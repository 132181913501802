import React from "react";
import Layout from "../../components/layout/Layout";
import "../../assets/css/DeveloperInformation.css";
import developerInformation1 from "../../assets/images/DeveloperInformation1.png";
import developerInformation2 from "../../assets/images/DeveloperInformation2.png";
import { Helmet } from "react-helmet";

const DeveloperInformation = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Developer Information, API Integration, Software Specifications, Virtue Life Partnerships, Online Therapy Solutions"
        />
        <meta
          name="description"
          content="Learn about the technical specifications and integration options for Virtue Life’s physical therapist software. Join us in revolutionizing online therapy."
        />
        <title>Developer Information | Virtue Life - Partner with Us</title>
      </Helmet>
      <Layout>
        <section className="developer-information-section">
          <div className="container">
            <div className="developer-information-content">
              <h1>Developer Information</h1>
              <p>
              VirtueLife integrates with multiple Practice Management Systems to streamline operations for practitioners, ensuring time savings and seamless data synchronization between platforms.
<br/><br/>

With VirtueLife, you can easily integrate any practice management system or Electronic Health Record (EHR) platform using secure token-based access. This enables your platform to efficiently retrieve and manage critical data such as exercise lists and pre-defined workout templates.
<br/><br/>


If you're using a practice management system or EHR, we're happy to assist you in integrating VirtueLife with your platform. VirtueLife's token-based API allows easy access to comprehensive exercise libraries and template lists, ensuring a smooth workflow for your practice. Get in touch with us to learn more!
              </p>
            </div>
            {/* Integration types section */}
            <div className="integration-types-section">
              <p className="heading-primary">Integration types</p>
              <div className="paragraph-content">
                <p>
                  Most of the practice management systems that integrate with
                  Virtue Life use (a combination of the) integration types listed
                  below.
                  <br />
                </p>
                <ol type="1" className="ol-li" style={{ listStyle: "decimal" }}>
                  <li>
                  Webhook Integration: Automatically download a PDF version of an assigned (or updated) exercise program from VirtueLife into your system.
                  </li>
                  <li>
                  VirtueLife RESTful API (Write): Using token-based access, assign exercises or templates to a patient directly from your platform’s user interface. Once assigned, VirtueLife generates a PDF of the workout plan, complete with calendar management.
                  </li>
                  <li>
                  VirtueLife RESTful API (Read): Set up webhooks to listen for our events and automatically collect patient outcome data such as exercise adherence, PROM (Patient-Reported Outcome Measures) scores, and messages.
                  </li>
                </ol>
              </div>
            </div>{" "}
            {/* Getting started section */}
            <div className="getting-started-section">
              <p className="heading-primary">Getting started</p>
              <div className="paragraph-content">
                <ol type="1" className="ol-li" style={{ listStyle: "decimal" }}>
                  <li>
                    Decide on the type of integration you&apos;d like to set up
                  </li>
                  <li>
                    Email support@virtuelife.ai so we can set you up on our
                    staging environment
                  </li>
                  <li>Develop &amp; test your integration</li>
                  <li>
                    Write clear step by step documentation for your users.
                    Include screenshots.
                  </li>
                  <li>
                    Email us when you&apos;ve completed your integration.
                    Include information for us to login to your system and test
                    the integration from our end.
                  </li>
                </ol>
              </div>
            </div>{" "}
            
          </div>
        </section>{" "}
      </Layout>
    </>
  );
};

export default DeveloperInformation;
