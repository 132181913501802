import React from "react";
import Layout from "../../components/layout/Layout";
import { Container, Row, Col } from "react-bootstrap";
import hepFeatures from "../../assets/images/hepFeatures-laptop.png";
import timeBack1 from "../../assets/images/timeBack1.png";
import timeBack2 from "../../assets/images/timeBack2.png";
import timeBack3 from "../../assets/images/timeBack3.png";
import timeBack4 from "../../assets/images/timeBack4.png";
import "../../assets/css/HEPFeatures.css";
import HEPFeatures1 from "../../assets/images/HEPFeatures1.gif";
import HEPFeatures2 from "../../assets/images/HEPFeatures2.gif";
import HEPFeatures3 from "../../assets/images/HEPFeatures3.gif";
import HEPFeatures4 from "../../assets/images/HEPFeatures4.gif";
import HEPFeatures5 from "../../assets/images/HEPFeatures5.gif";
import Testimonial from "../../components/testimonial/Testimonial";
import { Helmet } from "react-helmet";

const HEPFeatures = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Home Exersice Program Best Online Home Exercise Program, Home Physical Therapy Exercises"
        />
        <meta
          name="description"
          content="Find the best online home exercise program in the USA. Our tailored HEP for physical therapy supports your recovery and fitness goals from home!"
        />
        <title>
          Best Online Home Exercise Program in USA | HEP for Physical Therapy
          Software
        </title>
      </Helmet>
      <Layout>
        {/* physiotherapist section */}
        <section className="physiotherapist-section position-relative d-flex align-items-center">
          <div className="container">
            <div className="row my-5 py-4">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="header-below-content-part">
                  <h1>
                    Best Online Home Exercise Program for Physical Therapy (HEP)
                  </h1>
                  <p>
                    At Virtue Life, we enable therapists and patients to utilize
                    the benefits of the physical therapy experience. With our{" "}
                    <strong> Best Online Home Exercise Program,</strong> the
                    prescription for{" "}
                    <strong> home physical therapy exercises </strong>for a
                    quick recovery.
                  </p>

                  <div className="start-free-trial-orange-button">
                    <a
                      href="https://physiotherapist.virtuelife.ai/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="px-4">
                        <span>Book a FREE Trial</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="d-flex hepFeatures-image">
                  <img
                    src={hepFeatures}
                    alt=""
                    height={"100%"}
                    width={"100%"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>{" "}
        {/* Virtue Life gives you time back to focus on your patients section */}
        <section
          className="desktop-mobile-section time-back-section"
          style={{ background: "rgba(255, 252, 251, 1)" }}
        >
          <div className="container">
            <div className="exercise-protocol-title-part">
              <h2>Experience the difference at Virtue Life!</h2>
              <p>
                Virtue Life is changing how therapists and patients collaborate
                to streamline and facilitate home exercise prescriptions. Create
                better patient outcomes today using the tools and resources to
                support them during recovery.
              </p>
            </div>
            <Row className="align-items-center">
              <Col md={3} sm={12}>
                <div className="image-part">
                  <img
                    src={timeBack1}
                    alt="feature-banner"
                    width="277"
                    height="230"
                  />
                </div>
                <div>
                  <p className="feature-card-heading">
                    Home exercise <br />
                    library
                  </p>
                </div>
              </Col>
              <Col md={3} sm={12}>
                <div className="image-part">
                  <img
                    src={timeBack2}
                    alt="feature-banner"
                    width="277"
                    height="230"
                  />
                </div>
                <div>
                  <p className="feature-card-heading">
                    Exercise <br /> Protocol
                  </p>
                </div>
              </Col>
              <Col md={3} sm={12}>
                <div className="image-part">
                  <img
                    src={timeBack3}
                    alt="feature-banner"
                    width="277"
                    height="230"
                  />
                </div>

                <p className="feature-card-heading">
                  Patient education community
                </p>
              </Col>
              <Col md={3} sm={12}>
                <div className="image-part">
                  <img
                    src={timeBack4}
                    alt="feature-banner"
                    width="277"
                    height="230"
                  />
                </div>
                <div>
                  <p className="feature-card-heading">
                    Patient progress monitoring
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        {/* Home exercise library section */}
        <section
          className="exercise-home-library-section"
          style={{ paddingTop: "4rem" }}
        >
          <div className="container">
            <Row>
              <div className="d-flex justify-content-center">
                <div className="gif-image">
                  <img src={HEPFeatures1} alt="" width={120} height={120} />
                </div>
              </div>
              <div className="hep-features-gif-content light-theme">
                <h2>Home Exercise Library</h2>
                <p>
                  The Virtue Life library has an abundance of{" "}
                  <strong> home exercise programs.</strong>
                  From this, a therapist can choose from numerous movements to
                  tackle specific recovery needs. Your patient might require
                  direct post-operative rehabilitation or general mobility
                  improvement. Share easy-to-follow videos and PDFs with your
                  patients for better understanding & compliance towards their
                  program using mobile apps.
                </p>
              </div>
            </Row>
          </div>
        </section>
        {/* Home exercise protocol section */}
        <section className="exercise-home-protocol-section">
          <div className="container">
            <Row>
              <div className="d-flex justify-content-center">
                <div className="gif-image">
                  <img src={HEPFeatures2} alt="" width={120} height={120} />
                </div>
              </div>
              <div className="hep-features-gif-content light-theme">
                <h2>Customized Exercise Protocols</h2>
                <p>
                  Virtue Life enables a therapist to establish customized,{" "}
                  <strong> best online home exercise programs</strong> to
                  address the individual needs of every patient. Our templates
                  and tools can make it easy for patients to follow through with
                  flexible treatment plans tailored to their conditions.
                </p>
                <p>
                  Save time by creating templates of common protocols that can
                  easily be assigned to multiple patients. Devise a protocol
                  with your patients as your patients begin to progress or face
                  new challenges. Their treatment can change with their
                  recovery. In many ways, this means the patient has a say in
                  their exercises so you can go back and refine the protocol for
                  better results.
                </p>
              </div>
            </Row>
          </div>
        </section>
        {/* Description as patient’s convenient language section */}
        <section className="convenient-language-section">
          <div className="container">
            <Row>
              <div className="d-flex justify-content-center">
                <div className="gif-image">
                  <img src={HEPFeatures3} alt="" width={120} height={120} />
                </div>
              </div>
              <div className="hep-features-gif-content light-theme">
                <h2>Patient Description In Their Language</h2>
                <p>
                  At Virtue Life, we know how quality communication can be the
                  key to a patient's success. Hence, our platform offers
                  educational materials and{" "}
                  <strong> home physical therapy exercise </strong>
                  instructions in several languages to make the treatment plan
                  seamless and keep the patients actively engaged in recovery.
                </p>
              </div>
            </Row>
          </div>
        </section>
        {/* Monitoring patient progress section */}
        <section className="monitoring-progress-section">
          <div className="container">
            <Row>
              <div className="d-flex justify-content-center">
                <div className="gif-image">
                  <img src={HEPFeatures4} alt="" width={120} height={120} />
                </div>
              </div>
              <div className="hep-features-gif-content light-theme">
                <h2>Progress Monitoring to Better Outcomes</h2>
                <p>
                  Track, in real-time, how your patients are recovering through
                  our intuitive progress monitoring tools. Get real-time
                  insights into their progress and make data-driven decisions
                  about your patient's care as you modify their{" "}
                  <strong> home exercise programs.</strong>
                </p>
              </div>
            </Row>
          </div>
        </section>
        {/* Assign exercise with ease. Print, Email or assign via Mobile App section */}
        <section className="assign-exercise-section">
          <div className="container">
            <Row>
              <div className="d-flex justify-content-center">
                <div className="gif-image">
                  <img src={HEPFeatures5} alt="" width={120} height={120} />
                </div>
              </div>
              <div className="hep-features-gif-content light-theme">
                <h2>Easy Exercise Prescription</h2>
                <p>
                  Now you can click and assign the{" "}
                  <strong> Best Online Home Exercise Program </strong>to your
                  patients. Irrespective of whether it's by email/printouts by
                  Virtue Life Mobile App, you can ensure that it is always
                  accessible to your patients in their preferred format.
                </p>
              </div>
            </Row>
          </div>
        </section>
        <Testimonial />
        {/* try virtue life today section */}
        <section className="try-virtue-life-today-section">
          <div className="container">
            <div className="try-virtue-life-today-card">
              <div className="try-virtue-life-today-content">
                <p className="try-virtue-life-today-text">
                  Want to change your <br /> way of therapy practice?
                </p>
                <div className="store-name mt-4">
                  <a
                    href="https://physiotherapist.virtuelife.ai/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="px-4">
                      <span>Book a FREE Trial</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>{" "}
      </Layout>
    </>
  );
};

export default HEPFeatures;
