import React from "react";
import "../../assets/css/Certified.css";
import certified from "../../assets/images/certified.png";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Certified = () => {
  return (
    <>
      {/* certified section */}
      <section className="certified-section">
        <div className="container">
          <Row className="certified-section-box">
            <Col md={3} sm={12} className="certified-image">
              <div>
                <img src={certified} alt="certified" width="151" height="127" />
              </div>
            </Col>
            <Col md={9} sm={12} className="d-flex align-items-center">
              <div className="certified-content-part">
                <h2>Get Your Virtue Life Certified Excellence Clinic Badge </h2>
                <div className="store-name-certified mt-3 d-flex">
                <a href="mailto:support@virtuelife.ai?subject=Application for Virtue Life Badge&body=Dear Virtue Life team,%0D%0A%0D%0AI would like to apply for the Virtue Life Badge. Please find my details below:%0D%0A%0D%0A[Your Details Here]%0D%0A%0D%0AThank you,%0D%0A[Your Name]">
  <button className="px-4 certified-apply-now-button">
    Apply Now
  </button>
</a>

                  <Link to="/virtueLifeBadge">
                    <button className="certified-read-more-button ms-3">
                      Read More
                    </button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Certified;
