import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import Home from "./pages/home/Home";
import "bootstrap-icons/font/bootstrap-icons.css";
import Physiotherapist from "./pages/physiotherapist/Physiotherapist";
import HomeExerciseLibrary from "./pages/homeExerciseLibrary/HomeExerciseLibrary";
import Clinic from "./pages/clinic/Clinic";
import Patient from "./pages/patient/Patient";
import PatientEngagement from "./pages/patientEngagement/PatientEngagement";
import SecurityReliability from "./pages/securityReliability/SecurityReliability";
import AboutVirtueLife from "./pages/aboutVirtueLife/AboutVirtueLife";
import HEPFeatures from "./pages/hepFeatures/HEPFeatures";
import HealthcareSystems from "./pages/healthcareSystems/HealthcareSystems";
import VirtueLifeBadge from "./pages/virtueLifeBadge/VirtueLifeBadge";
import ContactUs from "./pages/contactUs/ContactUs";
import DeveloperInformation from "./pages/developerInformation/DeveloperInformation";
import Blog from "./pages/blog/Blog";
import BlogInnerPage from "./pages/blogInnerPage/BlogInnerPage";
import Pricing from "./pages/pricing/Pricing";
import Licence from "./components/licence/Licence";
import Terms from "./components/licence/Terms";
import Privacy from "./components/licence/Privacy";
import Refund from "./components/licence/Refund";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/physiotherapist" element={<Physiotherapist />} />
          <Route path="/clinic" element={<Clinic />} />
          <Route path="/patient" element={<Patient />} />
          <Route
            path="/homeexerciselibrary"
            element={<HomeExerciseLibrary />}
          />
          <Route path="/patientengagement" element={<PatientEngagement />} />
          <Route
            path="/securityreliability"
            element={<SecurityReliability />}
          />
          <Route path="/aboutvirtueLife" element={<AboutVirtueLife />} />
          <Route path="/hepfeatures" element={<HEPFeatures />} />
          <Route path="/healthcaresystems" element={<HealthcareSystems />} />
          <Route path="/virtuelifeBadge" element={<VirtueLifeBadge />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route
            path="/developerinformation"
            element={<DeveloperInformation />}
          />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogdetails" element={<BlogInnerPage />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path='/end-user-license-agreement' element={<Licence />} />
          <Route path='/terms' element={<Terms />} />
        <Route path='/privacy' element={<Privacy />} />
        {/* <Route path='/support' element={<Support />} /> */}
        <Route path='/refund' element={<Refund />} />
        <Route path="*" element={<Navigate to="/" />}  />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
