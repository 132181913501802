import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import "../../assets/css/Blog.css";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import blogimage from  "../../assets/images/Img1-min.png"
import blogimage2 from  "../../assets/images/Img2-min.png"
import blogimage3 from  "../../assets/images/Img3-min.png"
import blogimage4 from  "../../assets/images/Img4-min.png"

const Blog = () => {
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const [communitiesList, setcommunitiesList] = useState({
    items: [],
    has_more: false,
    totalPages: 0,
  });
  const [page, setPage] = useState(1);
  
  useEffect(() => {
    getCommunitiesList();
  }, [page]);
  async function getCommunitiesList() {
    setIsLoading(true); // Start loading
    try {
      let url = `https://api.virtuelife.ai/blogList?pageNumber=${page}&myCommunity=true`;
      const data = await axios.get(url);
      if (data?.status === 200) {
        if (page > 1) {
          setcommunitiesList((p) => ({
            ...p,
            items: communitiesList?.items.concat(data?.data?.communities),
          }));
        } else {
          setcommunitiesList((p) => ({ ...p, items: data?.data?.communities }));
        }
        // setcommunitiesList((e) => ({ ...e, items: data?.data?.data }))
        setcommunitiesList((e) => ({
          ...e,
          has_more: data?.data?.has_more,
          totalPages: data?.data?.totalPages,
        }));
      }
    } catch (error) {}
    setIsLoading(false); // Stop loading
  }
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Physiotherapy Blog, Wellness Tips, Therapy Articles, Virtue Life"
        />
        <meta
          name="description"
          content="Explore the latest articles, tips, and insights on physiotherapy, exercise, and wellness from the experts at Virtue Life. Stay informed and enhance your therapy journey!"
        />
        <title>Virtue Life Blog | Insights on Physiotherapy & Wellness</title>
      </Helmet>
      <Layout>
        <section className="blog-section">
          <div className="container">
            <div className="blog-content">
              <h1>Blog</h1>
            </div>
            <Row className="mt-4">
            {isLoading ? (
                        <div className="text-center">Loading...</div>
                      ) : (
                        <>
                          {communitiesList?.items &&
                            communitiesList?.items?.length === 0 && (
                              <div className="text-center">No Blog Found</div>
                            )}
                          {communitiesList?.items &&
                            communitiesList?.items?.map((item, index) => {
                              // Function to get next three communities
                              const getRandomCommunities = (currentIndex) => {
                                let filteredItems =
                                  communitiesList.items.filter(
                                    (_, idx) => idx !== currentIndex
                                  );
                                let randomCommunities = [];
                                for (
                                  let i = 0;
                                  i < 3 && filteredItems.length > 0;
                                  i++
                                ) {
                                  let randomIndex = Math.floor(
                                    Math.random() * filteredItems.length
                                  );
                                  randomCommunities.push(
                                    filteredItems[randomIndex]
                                  );
                                  filteredItems.splice(randomIndex, 1); // Remove the selected item
                                }
                                return randomCommunities;
                              };
                              return (
                                <>
                                  <Col
                                    xl="4"
                                    md="6"
                                    lg="12"
                                    key={index}
                                    className="d-flex aling-items-stretch mt-3"
                                  >
                                    <div
                                      className="community-card"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/blogDetails", {
                                          state: {
                                            comunity: item,
                                            nextCommunities:
                                              getRandomCommunities(index),
                                            allCommunities:
                                              communitiesList.items,
                                          },
                                        });
                                      }}
                                    >
                                       <div className="blog-card h-100">
                  <div className="blog-card-content d-flex align-items-center bg-transparent" >
                    <img src={index === 0  ? blogimage  :index === 1 ? blogimage2 :index === 2 ? blogimage3 : blogimage4  } className="img-fluid"  />
                    </div>
                  <div className="blog-card-details">
                    <p className="blog-heading">
                    {item?.title}
                    </p>
                    <p className="blog-text-style">
                    <p
                                                style={{
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  width: "100%",
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    item?.description
                                                      ?.split(/\s+/)
                                                      .slice(0,30)
                                                      .join(" ") +
                                                    (item?.description?.split(
                                                      /\s+/
                                                    ).length > 30
                                                      ? "..."
                                                      : ""),
                                                }}
                                              />
                                              
                    </p>
                  </div>{" "}
                </div>
                                    </div>
                                  </Col>
                                </>
                              );
                            })}{" "}
                        </>
                      )}
            </Row>

            

            {communitiesList.totalPages > 0 && (


            <div className="pagination">

{Array.from(
                                { length: communitiesList.totalPages },
                                (_, i) => i + 1
                              ).map((pageNumber) => (

                                <div className="pagination-text-container" key={pageNumber}
                                onClick={() => setPage(pageNumber)}>
                                <p className="pagination-text-heading">                                  {pageNumber}
</p>
                              </div>
                              ))}
            </div> ) }
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Blog;
