import React from "react";
import Layout from "../../components/layout/Layout";
import { Row, Col } from "react-bootstrap";
import "../../assets/css/AboutVirtueLife.css";
import listClinic from "../../assets/images/listClinic.png";
import { Helmet } from "react-helmet";

const AboutVirtueLife = () => {
  return (
    <>
      <Helmet>
        <meta name="keywords" content="About US" />
        <meta
          name="description"
          content="Explore the history of Virtue Life, our mission, and the journey that drives our commitment to enhancing health and wellness for all."
        />
        <title>Learn About Virtue Life History | About Us</title>
      </Helmet>
      <Layout>
        {/* about Virtue Life section */}
        <section className="about-virtue-life-section">
          <div className="container">
            <div className="about-virtue-life-title-part">
              <h2>The Journey Of Virtue Life</h2>
              <p>
                We are a personalized healthcare assistant & the most trusted
                patient companion. Virtue Life is a home exercise platform that
                enables you to create exercise programs for your patients by
                offering cutting-edge technologies that promote customized
                therapy adapted to individual requirements.
              </p>
            </div>
          </div>
        </section>
        {/* Our Story section */}
        <section className="our-story-section">
          <div className="container">
            <Row className="align-items-center">
              <Col md={6} sm={12}>
                <div>
                  <div className="our-story-title-part light-theme">
                    <h2>Our Story</h2>
                    <p>
                      Founded by a motivated team of healthcare specialists who
                      noticed inefficiencies within standard practices—Virtue
                      Life originated out of concern over obsolete techniques
                      and missing interaction between treatment providers'
                      workflows & those seeking support throughout recovery
                      procedures! Our platform targets not just efficiency but
                      also accessibility in healthcare delivery.
                    </p>
                    <p>
                      Over time, we have updated our platform based on user
                      feedback—physiotherapists' insights mixed with experiences
                      given by clinics and patients—ensuring our solutions
                      successfully fit real-world demand! Today, Virtue Life
                      stands proudly as one of America's top digital solutions
                      providers within this industry, trusted by many clinics
                      statewide!
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={4} sm={12} className="image-part ms-5">
                <div>
                  <img src={listClinic} alt="" width="518" height="428" />
                </div>
              </Col>
            </Row>
          </div>
        </section>
        {/* Who we are section */}
        <section className="who-we-are-section">
          <div className="container">
            <Row className="align-items-center">
              <Col md={6} sm={12}>
                <div>
                  <div className="who-we-are-title-part light-theme">
                    <h2>Who We Are?</h2>
                    <p>
                      Our focused team contains specialists who have created the
                      world-famous app 30-day fitness along with FlatTummy. Both
                      got ranked App of The Day at the App Store. Together, we
                      aspire to develop an environment where therapists handle
                      cases quickly to get individualized support—benefits from
                      smooth interactions enabled by our platform!
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={4} sm={12} className="image-part ms-5">
                <div>
                  <img src={listClinic} alt="" width="518" height="428" />
                </div>
              </Col>
            </Row>
          </div>
        </section>
        {/* global-scale */}
        <section className="global-scale-section">
          <div className="container">
            <Row className="align-items-center">
              <Col md={6} sm={12}>
                <div className="global-scale-content-part">
                  <div className="global-scale-title-part  light-theme">
                    <p>What’s New?</p>
                    <h2>Check Out what Virtue Life Can Offer!</h2>

                    <button className="global-scale-button">
                      <span>Book a FREE Trial</span>
                    </button>
                  </div>
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div>
                  <p className="global-text">
                    The Virtue Life <strong>physical therapy app </strong>is
                    designed to make your recovery quicker with safe and
                    reliable exercise plans. Here are some staggering stats that
                    speak volumes regarding our competence in this evolving &
                    advanced medical sector!
                  </p>
                  <div className="stats-container">
                    <div className="info-card">
                      <p className="statistic-text">500+</p>
                      <p className="clinic-stats-text-style">Global Clinics</p>
                    </div>
                    <div className="global-scale-vertical-divider" />
                    <div className="info-card">
                      <p className="statistic-text">1200+</p>
                      <p className="clinic-stats-text-style">
                        Healthcare Specialists
                      </p>
                    </div>
                    <div className="global-scale-vertical-divider" />
                    <div className="info-card">
                      <p className="statistic-text">26k+</p>
                      <p className="clinic-stats-text-style">
                        Trusted Patients
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        {/* Virtue Life Core Values section */}
        <section
          className="virtue-life-core-values-section"
          style={{ background: "rgba(255, 252, 251, 1)" }}
        >
          <div className="container">
            <div className="center-title-part">
              <h2>Our Core Values</h2>
              <p>
                At Virtue Life, we redefine physiotherapy using the latest tech
                solutions built exclusively for healthcare professionals &
                patients.
              </p>
              <div
                style={{ display: "flex", justifyContent: "center" }}
                className="start-free-trial-orange-button"
              >
                <button className="px-4">
                  <span>Book a FREE Trial</span>
                </button>
              </div>
            </div>

            <div className="mt-4">
              <Row>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Clients First Approach</h5>
                      <p>
                        We value our clients as our top-most priority. Our
                        service offerings incorporate a human touch to make them
                        feel acknowledged and appreciated.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Our Working Methodology</h5>
                      <p>
                        Acting swiftly by accomplishing desired tasks on time is
                        our bonafide working strategy. We simplify our approach
                        to work smarter.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Together - We Win</h5>
                      <p>
                        Virtue Life focuses on long-lasting collaboration &
                        celebrates milestones by stressing “we” rather than
                        “me.”
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default AboutVirtueLife;
