import React, { useEffect, useState } from "react";
import "../../assets/css/PhysioAnimation.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useNavigate } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const PhysioAnimation = ({ sections }) => {
  const [currentImage, setCurrentImage] = useState(sections[0].image);
  const navigate = useNavigate()
  useEffect(() => {
    let ctx = gsap.context(() => {
      const triggers = sections.map((section, index) => {
        return ScrollTrigger.create({
          trigger: `.scroll-section:nth-child(${index + 1})`,
          start: "top center",
          onEnter: () => setCurrentImage(section.image),
          onEnterBack: () => setCurrentImage(section.image),
        });
      });

      ScrollTrigger.create({
        trigger: ".scrollable-content",
        start: "top top",
        end: "bottom bottom",
        pin: ".fixed-image-container",
        scrub: true,
      });

      return () => {
        triggers.forEach((trigger) => trigger.kill());
      };
    });

    return () => ctx.revert();
  }, []);

  return (
    <div className="physio-animation-container">
      <div className="scrollable-content">
        {sections.map((section, index) => (
          <section key={index} className="scroll-section">
            <div className="physio-content-part">
              <div className="physio-title-part light-theme">
                {section.logo}
                <h2>{section.title}</h2>
                <p>{section.description}</p>
                <button className="physio-learn-more-button" onClick={() =>{
                  navigate(section?.navigateTo)
                }}>Learn more</button>
              </div>
              <div className="see-more-content">
                <div className="collaborative-exercise-section1">
                  <p className="highlighted-text-block">See also</p>
                </div>
                {section.extraContent.map((content, idx) => (
                  <p key={idx} className="text-block-container">
                    <span
                      className={
                        index === 2
                          ? "patient-highlighted-text"
                          : "physio-highlighted-text"
                      }
                    >
                      {content.highlighted}
                    </span>{" "}
                    {content.text}
                  </p>
                ))}
              </div>
            </div>
          </section>
        ))}
      </div>

      <div className="fixed-image-container sticky-image">
        <img
          src={currentImage}
          alt={
            sections.find((section) => section.image === currentImage)
              ?.imageAlt
          }
          className="fixed-image"
        />
      </div>
    </div>
  );
};

export default PhysioAnimation;
