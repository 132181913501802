import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import "../../assets/css/BlogInnerPage.css";
import { Row, Col } from "react-bootstrap";
import profile from "../../assets/images/profile.png";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import blogimage from  "../../assets/images/Img1-min.png"
import blogimage2 from  "../../assets/images/Img2-min.png"
import blogimage3 from  "../../assets/images/Img3-min.png"
import blogimage4 from  "../../assets/images/Img4-min.png"

const BlogInnerPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [communityData, setCommunityData] = useState({});
  const [communitiesList, setcommunitiesList] = useState({
    items: [],
    has_more: false,
  });
  useEffect(() => {
    if (location?.state?.comunity) {
      setCommunityData(location?.state?.comunity);
    }
    if (location?.state?.allCommunities) {
      // Filter out the current community using its _id
      const otherCommunities = location.state.allCommunities.filter(
        (item) => item?._id !== location?.state?.comunity?._id
      );

      // Shuffle the array and pick the first three items
      const shuffled = otherCommunities.sort(() => 0.5 - Math.random());
      let selected = shuffled.slice(0, 3);

      setcommunitiesList((prev) => ({
        ...prev,
        items: selected,
      }));
    }
    window.scrollTo(0, 0);
  }, [location?.state]);

  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Physiotherapy Tips, Wellness Advice, Virtue Life blog"
        />
        <meta
          name="description"
          content="Read our latest blog post. Get insights, tips, and advice to enhance your physiotherapy experience and overall wellness."
        />
        <title>Virtue Life - Your Physiotherapy Resource</title>
      </Helmet>
      <Layout>
        {/* blog Inner Page section */}
        <section className="blog-inner-page-section pb-0">
          <div className="container">
            <h1 className="blog-inner-page-heading">
            {communityData?.title}
            </h1>
            <div className="blog-profile-content">
              <img  src={
                            communityData?.posted_By?.profile_image
                              &&  communityData?.posted_By?.profile_image
                          } className="profile-image-container" />
              <div className="doctor-info-container">
                <p className="doctor-info-card">                          {communityData?.posted_By?.name}</p>
                <div className="vertical-divider" />
                <p className="doctor-info-card">{moment(communityData?.created_at).format(
                          "MMM DD YYYY"
                        )}{" "}</p>
              </div>
            </div>
            <div className="blog-inner-page-content">
              <div className="blog-inner-page-content-container">
                <p className="blog-inner-page-content-text-block" dangerouslySetInnerHTML={{
                              __html: communityData?.description,
                            }}>
                </p>
                {/* <div className="card-container" /> */}
              </div>
            </div>
            <hr style={{ padding: "0 35px" }} />
          </div>
        </section>
        {/* Blog-section */}
        <section className="blog-section">
          <div className="container">
            <div className="center-title-part">
              <h2>
                Ensuring Faster Recovery with Safe & Reliable Exercise Plans
              </h2>
              <p>
                We are here every step of your recovery process to prevent
                further injury and ensure safer recovery within a genuine
                timeframe.
              </p>
              <button className="blog-section-button">Learn more</button>
            </div>

            <Row className="mt-4">
            {communitiesList?.items &&
                        communitiesList?.items?.length === 0 && (
                          <div className="text-center">No Blog Found</div>
                        )}

{communitiesList?.items &&
                        communitiesList?.items?.map((item, index) => {
                          return (
<>
<Col lg={4} md={4} className="d-flex align-items-stretch">
                <div className="blog-card"  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/blogDetails", {
                                      state: {
                                        comunity: item,
                                        allCommunities:
                                          location?.state?.allCommunities,
                                      },
                                    });
                                  }}>
  <div className="blog-card-content d-flex align-items-center bg-transparent" >
                    <img src={index === 0  ? blogimage  :index === 1 ? blogimage2 :index === 2 ? blogimage3 : blogimage4  } className="img-fluid"  />
                    </div>                  <div className="blog-card-details">
                    <p className="blog-heading">
                      {item?.title}
                    </p>
                    <p className="blog-text-style"    dangerouslySetInnerHTML={{
                                              __html:
                                                item?.description
                                                  ?.split(/\s+/)
                                                  .slice(0, 30)
                                                  .join(" ") +
                                                (item?.description?.split(/\s+/)
                                                  .length > 30
                                                  ? "..."
                                                  : ""),
                                            }}>
                    </p>
                  </div>{" "}
                </div>
              </Col>
</>
                          )
                        })}
            </Row>
          </div>
        </section>{" "}
      </Layout>
    </>
  );
};

export default BlogInnerPage;
