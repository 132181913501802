import React from "react";
import Layout from "../../components/layout/Layout";
import { Container, Row, Col } from "react-bootstrap";
import clinic from "../../assets/images/clinic-laptop.png";
import "../../assets/css/Clinic.css";
import clinic1 from "../../assets/images/clinic1.gif";
import clinic2 from "../../assets/images/clinic2.gif";
import clinic3 from "../../assets/images/clinic3.gif";
import Testimonial from "../../components/testimonial/Testimonial";
import Certified from "../../components/certified/Certified";
import { Helmet } from "react-helmet";

const Clinic = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Physiotherapy Clinic Software in USA, physio clinic, Physiotherapy Clinic Software"
        />
        <meta
          name="description"
          content="Discover the top physiotherapy clinic software in the USA. Enhance your practice’s efficiency and patient care with our innovative solutions for physio clinics."
        />
        <title>
          Top Physiotherapy Clinic Software in USA | Physio Clinic | Virtue Life
        </title>
      </Helmet>
      <Layout>
        {/* clinic section */}
        <section className="clinic-section position-relative d-flex align-items-center">
          <div className="container">
            <div className="row my-5 py-4">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="header-below-content-part">
                  <h1>Clinic Management Made Easy</h1>
                  <p>
                    Optimize your <strong> physio clinic</strong> functions with
                    Virtue Life - Your trusted{" "}
                    <strong> physiotherapy clinic software in the USA.</strong>{" "}
                    Streamline exercise routines with personalized
                    prescriptions, improved patient outcomes & seamless
                    rehabilitation features for{" "}
                    <strong> physiotherapists</strong> & patients.
                  </p>

                  <div className="start-free-trial-orange-button">
                    <a
                      href="https://physiotherapist.Virtue Life.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="px-4">
                        <span>Book a FREE Trial</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="d-flex clinic-image">
                  <img src={clinic} alt="" height={"100%"} width={"100%"} />
                </div>
              </div>
            </div>
          </div>{" "}
        </section>

        {/* List your clinic on Virtue Life */}
        <section className="list-clinic-section">
          <div className="container">
            <Row>
              <div className="d-flex justify-content-center">
                <div className="gif-image">
                  <img src={clinic1} alt="" />
                </div>
              </div>
              <div className="clinic-gif-content light-theme">
                <h2>List Your Clinic on Virtue Life</h2>
                <p>
                  Promote your clinic’s visibility by listing them on Virtue Life.
                  Our <strong> physiotherapy clinic software</strong> offers a
                  chance to link with an extensive patient pool, boosting
                  quality healthcare in the highly competitive{" "}
                  <strong> physio clinic</strong> landscape.
                </p>
              </div>
            </Row>
          </div>
        </section>

        {/* Add physiotherapist and patient */}
        <section className="physio-patient-section">
          <div className="container">
            <Row>
              <div className="d-flex justify-content-center">
                <div className="gif-image">
                  <img src={clinic2} alt="" />
                </div>
              </div>

              <div className="clinic-gif-content light-theme">
                <h2>Add Physiotherapist & Patient Profiles</h2>
                <p>
                  Enhance the smooth onboarding process for physiotherapists &
                  patients with our modernized Virtue Life software. Monitor each
                  profile individually & retrieve detailed analytics to assist
                  in making optimal treatment decisions.
                </p>
              </div>
            </Row>
          </div>
        </section>

        {/* Manage clinic data and Integrate with your app */}
        <section className="manage-clinic-data-section">
          <div className="container">
            <Row>
              <div className="d-flex justify-content-center">
                <div className="gif-image">
                  <img src={clinic3} alt="" />
                </div>
              </div>
              <div className="clinic-gif-content light-theme">
                <h2>Manage Clinic Data</h2>
                <p>
                  Handle confidential clinical information securely with
                  Virtue Life’s advanced{" "}
                  <strong>physiotherapy clinic software in the USA.</strong>
                  Our platform’s flexibility offers end-to-end integration with
                  existing devices by assisting your{" "}
                  <strong> physio clinic</strong> with exceptional data
                  integrity & streamlining workflows.
                </p>
              </div>
            </Row>
          </div>
        </section>

        <Testimonial />

        <Certified />
      </Layout>
    </>
  );
};

export default Clinic;
