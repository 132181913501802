import React from "react";
import Layout from "../../components/layout/Layout";
import { Container, Row, Col } from "react-bootstrap";
import patient from "../../assets/images/patient.png";
import "../../assets/css/Patient.css";
import appStore from "../../assets/images/app-store.png";
import playStore from "../../assets/images/play-store.png";
import physioHelpVirtueLife from "../../assets/images/physio-help-virtue-life.png";
import Testimonial from "../../components/testimonial/Testimonial";
import { Helmet } from "react-helmet";

const Patient = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Patient portal, online therapy, physical therapy management, patient communication, therapy tracking, Virtue Life"
        />
        <meta
          name="description"
          content="Access your personalized therapy plans, track progress, and communicate with your physical therapist seamlessly through the RecureMe patient portal."
        />
        <title>Patient Portal | Virtue Life Therapy Journey</title>
      </Helmet>

      <Layout>
        {/* patient section */}
        <section className="patient-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="header-below-content-part">
                  <h1>Relive Your Transformational Journey with Virtue Life</h1>
                  <p>
                  Virtue Life is an advanced{" "}
                    <strong> digital patient engagement platform </strong>
                    tailored to assist in specialized patient recovery. From
                    home exercise workouts to online therapist consultations,
                    our
                    <strong> patient engagement solutions</strong> have it all.
                  </p>

                  <div className="start-free-trial-orange-button">
                    <a
                      href="https://physiotherapist.virtuelife.ai/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="px-4">
                        <span>Book a FREE Trial</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="image-part">
                  <img src={patient} alt="" height={494} width={725} />
                </div>
              </div>
            </div>
          </div>{" "}
        </section>

        {/* Find Top-Rated Doctors and Physiotherapists with Ease section */}
        <section
          className="ucpm-doctors-section"
          style={{ background: "rgba(255, 252, 251, 1)" }}
        >
          <div className="container">
            <div className="center-title-part">
              <h2>How does Virtue Life Enhance Your Care Experience?</h2>{" "}
              <p>
                Our <strong> digital patient engagement platform </strong>is
                curated to ensure uninterrupted interaction between patients &
                world-class healthcare providers, fostering robust connections &
                improved <strong> patient care.</strong>
              </p>
            </div>

            <div className="mt-4">
              <Row>
                <Col lg={4} md={4} sm={12}>
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Easy Scheduling</h5>
                      <p>
                        Book appointments effortlessly with flexibility that
                        meet your daily routines, ensuring zero distractions to
                        your recovery journey.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Personalized Therapy</h5>
                      <p>
                        Receive customized treatment plans to accomplish your
                        rehabilitation demands and unique health goals via
                        expert Physios.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Real-Time Tracking</h5>
                      <p>
                        Manage your progression, track daily performance & adapt
                        your exercises based on real-time feedback to meet the
                        desired outcomes.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Expert Guidance</h5>
                      <p>
                        Get professional insights from highly qualified physical
                        therapists, offering clarity throughout your therapy
                        process for a quick recovery.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100 ">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Highly Secured Platform</h5>
                      <p>
                        Secure your data with advanced security protocols for
                        end-to-end confidentiality & compliance with modern
                        industrial standards.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Seamless Reporting</h5>
                      <p>
                        Get access to detailed progress reports & analytics to
                        keep you informed, engaged, and motivated over your
                        recovery process.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>

        {/* How Physiotherapist can help you with Virtue Life desktop and mobile apps section */}
        <section className="desktop-mobile-section">
          <div className="container">
            <Row className="align-items-center">
              <Col md={7} sm={12}>
                <div>
                  <div className="desktop-mobile-title-part light-theme">
                    <h2>What’s NEW in Virtue Life for Patients?</h2>
                    <p>
                      The patient can opt for a home workout at their fingertips
                      using our detailed video guide from the physio. Physio can
                      also intimate & edit challenging levels of exercise &
                      offer comments on each exercise & workout. They can also
                      perform home exercises via iOS, Android & web-based mobile
                      apps.
                    </p>
                  </div>
                  <div className="desktop-mobile-list-part">
                    <ul className="desktop-mobile-patient list-unstyled">
                      <div>
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>Video-Guided Exercises</div>
                        </li>
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>Progress Tracking</div>
                        </li>
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>Customized Workout Plans</div>
                        </li>
                      </div>
                      <div className="start-margin">
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>Multi-Device Compatibility</div>
                        </li>
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>Personalized Adjustments</div>
                        </li>
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>Interactive Support</div>
                        </li>
                      </div>
                    </ul>
                  </div>
                  <div
                    className="app-play-button"
                    style={{ textAlign: "start" }}
                  >
                    <button
                      className="appStore"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "https://apps.apple.com/in/app/virtue-life-patient-wellness/id6476655418",
                          "_blank"
                        );
                      }}
                    >
                      <img src={appStore} alt="appStore" className="me-2" />
                    </button>
                    <button
                      className="playStore"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.recureme.patient&hl=en_IN&gl=US&pli=1",
                          "_blank"
                        );
                      }}
                    >
                      <img src={playStore} alt="playStore" />
                    </button>
                  </div>
                </div>
              </Col>
              <Col md={4} sm={12}>
                <div className="image-part">
                  <img
                    src={physioHelpVirtueLife}
                    alt="feature-banner"
                    width="445"
                    height="484"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </section>

        {/* step section */}
        <section className="step-section">
          <div className="container">
            <div className="center-title-part">
              <h2>Start Your Patient Care Journey With 3 Simple Steps!</h2>
            </div>

            <Row>
              <Col
                lg={4}
                md={4}
                sm={12}
                className="step-section-data-card-grid"
              >
                <div className="d-flex align-items-center">
                  <div className="w-100 d-none d-md-block"></div>
                  <div className="mx-auto">
                    <div className="sequential-number-list">1</div>
                  </div>
                  <div className="dashed-border-bottom w-100 d-none d-md-block"></div>
                </div>
                <div className="dashed-border-left pt-3 w-0 m-auto"></div>
                <div className="custom-card-step-section mx-3">
                  <h5>Create Your Account</h5>
                  <p>
                    Sign up by filling in your details to access personalized
                    patient care & online home workouts.
                  </p>
                </div>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={12}
                className="step-section-data-card-grid"
              >
                <div className="dashed-border-left pt-3 w-0 m-auto d-md-none d-block"></div>
                <div className="d-flex align-items-center">
                  <div className="dashed-border-bottom w-100 d-none d-md-block"></div>
                  <div className="mx-auto">
                    <div className="sequential-number-list">2</div>
                  </div>
                  <div className="dashed-border-bottom w-100 d-none d-md-block"></div>
                </div>
                <div className="dashed-border-left pt-3 w-0 m-auto"></div>
                <div className="custom-card-step-section mx-3">
                  <h5>Book an Appointment</h5>
                  <p>
                    Schedule your session online by connecting with specialists
                    & get a custom workout plan tailored to your needs.{" "}
                  </p>
                </div>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={12}
                className="step-section-data-card-grid"
              >
                <div className="dashed-border-left pt-3 w-0 m-auto d-md-none d-block"></div>
                <div className="d-flex align-items-center">
                  <div
                    className="dashed-border-bottom d-none d-md-block"
                    style={{ width: "40%" }}
                  ></div>
                  <div className="number-margin">
                    <div className="sequential-number-list">3</div>
                  </div>
                  <div className="dashed-border-bottom w-100 d-none d-lg-none"></div>
                </div>
                <div className="dashed-border-left pt-3 w-0 m-auto"></div>
                <div className="custom-card-step-section mx-3 ">
                  <h5>Begin Your Online Workouts</h5>
                  <p>
                    Engage in home workout exercises, track your progress, and
                    get continuous support to optimize your recovery journey.
                  </p>
                </div>
              </Col>
            </Row>
            <div
              className="start-free-trial-orange-button mt-4"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <a
                href="https://physiotherapist.virtuelife.ai/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="px-4">
                  <span>Book a FREE Trial</span>
                </button>
              </a>
            </div>
          </div>
        </section>

        <Testimonial />
      </Layout>
    </>
  );
};

export default Patient;
