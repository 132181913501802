import React from "react";
import Layout from "../../components/layout/Layout";
import virtueLifeBadge from "../../assets/images/VirtueLifeBadge.png";
import { Row, Col } from "react-bootstrap";
import "../../assets/css/VirtueLifeBadge.css";
import securityReliability1 from "../../assets/images/securityReliability1.gif";
import securityReliability2 from "../../assets/images/securityReliability2.gif";
import securityReliability3 from "../../assets/images/securityReliability3.gif";
import { Helmet } from "react-helmet";

const VirtueLifeBadge = () => {
  return (
    <>
      <Helmet>
        <meta name="keywords" content="Physio Clinic Budget" />
        <meta
          name="description"
          content="Manage your physio clinic budget effectively with our expert tips. Discover the best clinic budget strategies to maximize resources and enhance patient care!"
        />
        <title>Physio Clinic Budget | Best Clinic Budget</title>
      </Helmet>

      <Layout>
        {/* Virtue Life Badge section */}
        <section className="virtue-life-badge-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="header-below-content-part">
                  <h1>
                  Virtue Life Certified Clinic Badge: The Improved Standard of
                    Patient Care
                  </h1>
                  <p>
                  Virtue Life believes patient care & efficiency go hand-in-hand.
                    Our customized{" "}
                    <strong> Certified Excellence Clinic Badge</strong> program
                    is designed to help clinics meet standards of excellence in
                    patient care & clinic efficiency.
                  </p>

                  <div className="start-free-trial-orange-button">
                    <a
                      href="https://physiotherapist.virtuelife.ai/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="px-4">
                        <span>Book a FREE Trial</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="image-part">
                  <img src={virtueLifeBadge} alt="" height={330} width={560} />
                </div>
              </div>
            </div>
          </div>{" "}
        </section>{" "}
        {/* Professional Recognition section */}
        <section className="professional-recognition-section">
          <div className="container">
            <Row>
              <div className="d-flex justify-content-center">
                <div className="gif-image">
                  <img
                    src={securityReliability1}
                    alt=""
                    width={120}
                    height={120}
                  />
                </div>
              </div>
              <div className="virtue-life-badge-gif-content light-theme">
                <h2>Professional Validation</h2>
                <p>
                  The{" "}
                  <strong> Virtue Life Certified Excellence Clinic badge</strong>{" "}
                  is a badge of distinction telling patients and peers that you
                  are a leader in the healthcare industry. This badge puts
                  professionalism in your clinic, showing patients' focus on
                  top-class care and updated healthcare solutions. It’s
                  user-friendly and ensures scheduling, patient management, and
                  exercise tracking is smooth.
                </p>
                <p>
                  This badge makes patients confident in seeking your service
                  because your clinic features high standards of care and
                  transparent operations. It showcases pride in this badge in
                  your clinic & over your online profiles by gaining the
                  visibility and trust required for building your practice.
                </p>
              </div>
            </Row>
          </div>
        </section>
        {/* Enhanced Visibility section */}
        <section className="increased-visibility-section">
          <div className="container">
            <Row>
              <div className="d-flex justify-content-center">
                <div className="gif-image">
                  <img
                    src={securityReliability2}
                    alt=""
                    width={120}
                    height={120}
                  />
                </div>
              </div>
              <div className="virtue-life-badge-gif-content light-theme">
                <h2>Enhanced Visibility</h2>
                <p>
                  In a highly competitive marketplace, it’s insufficient to
                  provide service but seen, known, and trusted. With the{" "}
                  <strong> Virtue Life Certified Excellence Clinic Badge,</strong>{" "}
                  you'll never run out of opportunities to display your
                  certification on different marketing channels, from your
                  clinic's website to social media, known for the clinic's
                  reputation.
                </p>
                <p>
                  Make your <strong> physio clinic </strong>stand out as a
                  source of quality advanced physiotherapy and rehabilitation
                  for your patients. One way of achieving all this is having
                  effective
                  <strong> physio clinic budget </strong>management to attain
                  more visibility and make it stand out in the healthcare
                  market.
                </p>
              </div>
            </Row>
          </div>
        </section>
        {/* Perks of Virtue Life Badge section */}
        <section className="increased-visibility-section">
          <div className="container">
            <Row>
              <div className="d-flex justify-content-center">
                <div className="gif-image">
                  <img
                    src={securityReliability3}
                    alt=""
                    width={120}
                    height={120}
                  />
                </div>
              </div>
              <div className="virtue-life-badge-gif-content light-theme">
                <h2>Perks of Virtue Life Badge</h2>
                <p>
                  With a certified Virtue Life badge, you'll benefit from many
                  resources for your business. Get top-ranked professionals
                  through Virtue Life to ensure the best patient outcomes. With one
                  easy-to-use system, all things related to scheduling,
                  managing, and documenting the patient will be available in
                  Virtue Life.
                </p>
                <p>
                  Ensure that you stay properly organized, compliant, and
                  efficient. Utilize modern exercises that track training and
                  progress while maintaining state-of-the-art digital safety and
                  efficiency. The expert advice on managing your{" "}
                  <strong> physio clinic budget </strong>can leverage resources
                  and better care for the patients, ensuring your clinic thrives
                  in this competitive marketplace.
                </p>
              </div>
            </Row>
          </div>
        </section>
        {/* Find Top-Rated Doctors and Physiotherapists with Ease section */}
        <section
          className="ucpm-doctors-section"
          style={{ background: "rgba(255, 252, 251, 1)" }}
        >
          <div className="container">
            <div className="center-title-part">
              <h2>Time To Locate Top-Rated Doctors & Physiotherapists</h2>
              <p>
                Witness modern patient care management & friendly collaboration
                via our platform designed to empower healthcare professionals &
                streamline care delivery.
              </p>
            </div>

            <div className="mt-4">
              <Row>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Dedication To Quality</h5>
                      <p>
                        Our badge isn't just a badge; it's about building
                        excellence in quality care.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Seamless Usage</h5>
                      <p>
                        The clinic must be seen actively using the Virtue Life
                        software for managing, scheduling, and tracking the
                        patient data & treatment.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Patient Care</h5>
                      <p>
                        The ability to provide quality service in the health
                        clinic like reliable treatment outcomes & awareness from
                        the patients.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Patient Suggestion</h5>
                      <p>
                        High rating scores on continuous ratings given by
                        patients which depict trust and satisfaction.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100 ">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Digital Literacy</h5>
                      <p>
                        Digital competence in utilizing the Virtue Life software
                        for monitoring the patient progression & always ensuring
                        that the documentation is updated.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Clinic Infrastructure</h5>
                      <p>
                        Your clinic should be endowed with modern equipment and
                        facilities that are par with or at least perform better
                        than what is expected of your industry standards.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        {/* step section */}
        <section className="step-section">
          <div className="container">
            <div className="center-title-part">
              <h2>How to Get the Badge at Virtue Life?</h2>
              <p>
                Reaching the{" "}
                <strong>Virtue Life Certified Excellence Clinic Badge</strong> is
                an easy task, but one that allows only the best-performing
                clinics to get recognized.{" "}
              </p>
            </div>

            <Row>
              <Col
                lg={4}
                md={4}
                sm={12}
                className="step-section-data-card-grid"
              >
                <div className="d-flex align-items-center">
                  <div className="w-100 d-none d-md-block"></div>
                  <div className="mx-auto">
                    <div className="sequential-number-list">1</div>
                  </div>
                  <div className="dashed-border-bottom w-100 d-none d-md-block"></div>
                </div>
                <div className="dashed-border-left pt-3 w-0 m-auto"></div>
                <div
                  className="custom-card-step-section mx-3"
                  style={{ height: "69%" }}
                >
                  <h5>Submit Your Application</h5>
                  <p>
                    Fill out the application form, and add testimonials by
                    successful patients to authenticate the quality of care.
                  </p>
                </div>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={12}
                className="step-section-data-card-grid"
              >
                <div className="dashed-border-left pt-3 w-0 m-auto d-md-none d-block"></div>
                <div className="d-flex align-items-center">
                  <div className="dashed-border-bottom w-100 d-none d-md-block"></div>
                  <div className="mx-auto">
                    <div className="sequential-number-list">2</div>
                  </div>
                  <div className="dashed-border-bottom w-100 d-none d-md-block"></div>
                </div>
                <div className="dashed-border-left pt-3 w-0 m-auto"></div>
                <div className="custom-card-step-section mx-3">
                  <h5>Performance Assessment</h5>
                  <p>
                    Based on our assessment system, Virtue Life experts will review
                    your clinic's performance on our quality standards.
                  </p>
                </div>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={12}
                className="step-section-data-card-grid"
              >
                <div className="dashed-border-left pt-3 w-0 m-auto d-md-none d-block"></div>
                <div className="d-flex align-items-center">
                  <div
                    className="dashed-border-bottom d-none d-md-block"
                    style={{ width: "40%" }}
                  ></div>
                  <div className="number-margin">
                    <div className="sequential-number-list">3</div>
                  </div>
                  <div className="dashed-border-bottom w-100 d-none d-lg-none"></div>
                </div>
                <div className="dashed-border-left pt-3 w-0 m-auto"></div>
                <div className="custom-card-step-section mx-3 ">
                  <h5>Recognition & Accreditation</h5>
                  <p>
                    On completing our assessment, your clinic will be affiliated
                    with the{" "}
                    <strong>
                      {" "}
                      Virtue Life Certified Excellence Clinic Badge.
                    </strong>
                  </p>
                </div>
              </Col>
            </Row>
            <div
              className="start-free-trial-orange-button mt-4"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <a
                href="https://physiotherapist.virtuelife.ai/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="px-4">
                  <span>Book a FREE Trial</span>
                </button>
              </a>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default VirtueLifeBadge;
