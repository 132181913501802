import React from "react";
import Layout from "../../components/layout/Layout";
import "../../assets/css/HealthcareSystems.css";
import { Container, Row, Col } from "react-bootstrap";
import healthcareSystems from "../../assets/images/HealthcareSystems-laptop.png";
import healthcareSystems1 from "../../assets/images/HealthcareSystems1.gif";
import healthcareSystems2 from "../../assets/images/HealthcareSystems2.gif";
import { Helmet } from "react-helmet";

const HealthcareSystems = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Healthcare Specialist in USA, Healthcare Software, Therapy Service"
        />
        <meta
          name="description"
          content="Discover the best healthcare specialists in the USA using advanced healthcare software. Streamline therapy services for optimal patient care today!"
        />
        <title>
          Best Healthcare Specialist in USA | Healthcare Software | Therapy
          Service Software
        </title>
      </Helmet>

      <Layout>
        {/* Healthcare systems section */}
        <section className="healthcare-systems-section position-relative d-flex align-items-center">
          <div className="container">
            <div className="row my-5 py-4">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="header-below-content-part">
                  <h1>We Are The Best Healthcare Specialist in the USA</h1>
                  <p>
                    The future of healthcare will be found in our advanced
                    solutions designed with clinics, hospitals, and therapy
                    centers in mind. Innovative health software that ensures
                    operational efficiency while providing better patient care
                    is why we remain at the top of{" "}
                    <strong> healthcare specialists in the USA.</strong>
                  </p>

                  <div className="start-free-trial-orange-button">
                    <a
                      href="https://physiotherapist.virtuelife.ai/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="px-4">
                        <span>Book a FREE Trial</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="d-flex healthcareSystems-image">
                  <img
                    src={healthcareSystems}
                    alt=""
                    height={"100%"}
                    width={"100%"}
                  />
                </div>
              </div>
            </div>
          </div>{" "}
        </section>{" "}
        {/* Lorem ipsum dolor sit amet, consectetur adipiscing section */}
        <section
          className="virtue-life-core-values-section"
          style={{ background: "rgba(255, 252, 251, 1)" }}
        >
          <div className="container">
            <div className="center-title-part">
              <h2>Why Choose Our Healthcare Solutions?</h2>
              <p>
                Our software has relied on{" "}
                <strong>healthcare experts in the USA </strong>for unmatched
                reliability, security, and support. Focusing on operational
                efficiency and patient satisfaction, our advanced treatment
                software ensures that everything healthcare professionals need
                is delivered to them to succeed.
              </p>
              <div className="start-free-trial-orange-button">
                <a
                  href="https://physiotherapist.virtuelife.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="px-4">
                    <span> Book Your FREE Trial</span>
                  </button>
                </a>
              </div>
            </div>

            <div className="mt-4">
              <Row>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Catering to the Digital Needs of Clients </h5>
                      <p>
                        Manage content delivery, instant notifications &
                        seamless engagement in iOS, Android & web apps via
                        emails, PDFs, or handouts.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Enhanced Outcomes & Accelerated Client Journey</h5>
                      <p>
                        Onboard new plan members quickly through devices they
                        love to work with. Allow self-management to save time &
                        resources.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Create your Personalized Outcome-based Database </h5>
                      <p>
                        Use Virtue Life’s data tools to create an individual data
                        repository. Perform sophisticated analysis using our
                        tools & services.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        {/* Exercise prescription & Education section */}
        <section className="exercise-education-section">
          <div className="container">
            <Row>
              <div className="d-flex justify-content-center">
                <div className="gif-image">
                  <img src={healthcareSystems1} alt="" />
                </div>
              </div>
              <div className="exercise-education-title-part light-theme">
                <h2>Revolutionize Exercise Prescription & Education</h2>
                <p>
                  As a leading{" "}
                  <strong> Healthcare Specialist in the USA,</strong> Virtue Life
                  revolutionizes patient care by providing comprehensive{" "}
                  <strong> therapy services.</strong> Empower your patients with
                  uniquely personalized exercise protocols & library that can
                  lead to faster recoveries among patients.
                </p>
              </div>
              <div className="">
                <ul className="desktop-mobile-patient healthcare-systems-ul">
                  <div>
                    <li>
                      <span className="me-2">
                        <svg
                          width="26"
                          height="26"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success-small"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                      <div>2000+ Reviewed Exercise Videos</div>
                    </li>
                    <li>
                      <span className="me-2">
                        <svg
                          width="26"
                          height="26"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success-small"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                      <div>Educational Resources</div>
                    </li>
                    <li>
                      <span className="me-2">
                        <svg
                          width="26"
                          height="26"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success-small"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                      <div>Outcome Measures</div>
                    </li>
                  </div>
                  <div className="ms-3">
                    <li>
                      <span className="me-2">
                        <svg
                          width="26"
                          height="26"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success-small"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                      <div>Upload Your Videos</div>
                    </li>
                    <li>
                      <span className="me-2">
                        <svg
                          width="26"
                          height="26"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success-small"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                      <div>Create Custom Templates</div>
                    </li>
                  </div>
                </ul>
              </div>
            </Row>
          </div>
        </section>
        {/* Client Onboarding section */}
        <section className="client-onboarding-section">
          <div className="container">
            <Row>
              <div className="d-flex justify-content-center">
                <div className="gif-image">
                  <img src={healthcareSystems2} alt="" />
                </div>
              </div>

              <div className="client-onboarding-title-part light-theme">
                <h2>Easy Client Registration & Engagement</h2>
                <p>
                  Contact your patients before their first appointment, and have
                  them feel supported. Virtue Life interfaces with your practice
                  management system for smooth appointment scheduling, assigning
                  workouts & seamless content access in one convenient app.
                </p>
              </div>
              <div>
                <ul className="desktop-mobile-patient healthcare-systems-ul">
                  <div>
                    <li>
                      <span className="me-2">
                        <svg
                          width="26"
                          height="26"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success-small"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                      <div>
                        Pre-appointment engagement before their first
                        visit/consultation.{" "}
                      </div>
                    </li>
                    <li>
                      <span className="me-2">
                        <svg
                          width="26"
                          height="26"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success-small"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                      <div>
                        Seamlessly integrate your practice management system.
                      </div>
                    </li>
                  </div>
                  <div className="ms-3">
                    <li>
                      <span className="me-2">
                        <svg
                          width="26"
                          height="26"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success-small"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                      <div>
                        Convenient booking for patients via the Virtue Life app to
                        boost patient engagement.{" "}
                      </div>
                    </li>
                  </div>
                </ul>
              </div>
            </Row>
          </div>
        </section>
        {/* try virtue life today section */}
        <section className="try-virtue-life-today-section">
          <div className="container">
            <div className="try-virtue-life-today-card">
              <div className="try-virtue-life-today-content">
                <p className="try-virtue-life-today-heading">Try Virtue Life</p>
                <p className="try-virtue-life-today-text">Get Started Now!</p>
                <div className="store-name mt-4">
                  <a
                    href="https://physiotherapist.virtuelife.ai/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="px-4">
                      <span>Book a 7 Days FREE Trial</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>{" "}
      </Layout>
    </>
  );
};

export default HealthcareSystems;
