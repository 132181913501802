import React from "react";
import Layout from "../../components/layout/Layout";
import { Row, Col } from "react-bootstrap";
import securityReliability from "../../assets/images/secutiryReliability.png";
import "../../assets/css/SecurityReliability.css";
import securityReliability1 from "../../assets/images/securityReliability1.gif";
import securityReliability2 from "../../assets/images/securityReliability2.gif";
import securityReliability3 from "../../assets/images/securityReliability3.gif";
import { Helmet } from "react-helmet";

const SecurityReliability = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Reliability in Information Security, Security Reliability in Physiotherapy"
        />
        <meta
          name="description"
          content="Discover the best security reliability in physiotherapy. Ensure your data is protected with our trusted solutions for optimal reliability and security."
        />
        <title>
          Best Security Reliability in Physiotherapy | Reliability and Security
        </title>
      </Helmet>

      <Layout>
        {/* security reliability section */}
        <section className="security-reliability-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="header-below-content-part">
                  <h1>Outstanding Security & Reliability</h1>
                  <p>
                    At Virtue Life, security isn't simply an afterthought; it's
                    incorporated into every facet of our platform. We recognize
                    the importance of sensitive health data & maintain high
                    standards for dependability and protection measures via our
                    modern software solutions!
                  </p>

                  <div className="start-free-trial-orange-button">
                    <a
                      href="https://physiotherapist.virtuelife.ai/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="px-4">
                        <span>Book a FREE Trial</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="image-part">
                  <img
                    src={securityReliability}
                    alt=""
                    height={264}
                    width={590}
                  />
                </div>
              </div>
            </div>
          </div>{" "}
        </section>
        {/* Secure Access section */}
        <section className="secure-access-section">
          <div className="container">
            <Row>
              <div className="d-flex justify-content-center">
                <div className="gif-image">
                  <img
                    src={securityReliability1}
                    alt=""
                    width={120}
                    height={120}
                  />
                </div>
              </div>
              <div className="security-reliability-gif-content light-theme">
                <h2>Secured Access </h2>
                <p>
                  We ensure end-to-end safety with role-based access control. Be
                  it a clinic, physiotherapist, or patient, everyone is granted
                  specialized access. However, sensitive data is only accessible
                  to an authorized user base.
                </p>
              </div>
            </Row>
          </div>
        </section>
        {/* Uninterrupted workflow section */}
        <section className="uninterrupted-workflow-section">
          <div className="container">
            <Row>
              <div className="d-flex justify-content-center">
                <div className="gif-image">
                  <img
                    src={securityReliability2}
                    alt=""
                    width={120}
                    height={120}
                  />
                </div>
              </div>
              <div className="security-reliability-gif-content light-theme">
                <h2>Seamless Workflow </h2>
                <p>
                  Get a chance to experience seamless operational workflows via
                  our platform including 99.9% uptime, automated backups &
                  consistent performance by guaranteeing zero interruptions in
                  managing your daily healthcare routine.
                </p>
              </div>
            </Row>
          </div>
        </section>
        {/* Realtime Notifications section */}
        <section className="realtime-notifications-section">
          <div className="container">
            <Row>
              <div className="d-flex justify-content-center">
                <div className="gif-image">
                  <img
                    src={securityReliability3}
                    alt=""
                    width={120}
                    height={120}
                  />
                </div>
              </div>
              <div className="security-reliability-gif-content light-theme">
                <h2>Real-time Notifications </h2>
                <p>
                  Stay informed with quick notifications from wake-up reminders
                  on appointments & workout progress to overall reports across
                  multiple stakeholders who are instantly notified for timely
                  operations with advanced{" "}
                  <strong> security reliability in physiotherapy.</strong>
                </p>
              </div>
            </Row>
          </div>
        </section>
        {/* Controls that protect confidentiality section */}
        <section
          className="ucpm-doctors-section"
          style={{ background: "rgba(255, 252, 251, 1)" }}
        >
          <div className="container">
            <div className="center-title-part">
              <h2>Protecting Valuable Data in Every Step of the Way</h2>
              <p>
                Virtue Life ensures high-end data security via end-to-end
                encryption, robust access controls, and HIPAA compliance based
                on industry standards to protect the{" "}
                <strong> reliability of information security.</strong>
              </p>
            </div>

            <div className="mt-4">
              <Row>
                <Col lg={4} md={4} sm={12}>
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Privacy-first</h5>
                      <p>
                        Your data is safely encrypted & we ensure each patient's
                        details remain private with our privacy-first policy.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Compliance Excellence</h5>
                      <p>
                        We accomplish industry-specific regulations for
                        safeguarding patient health data.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Enhanced Confidentiality</h5>
                      <p>
                        Only authorized personnel are granted access to prevent
                        unethical data exposure.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Secure Development Lifecycle</h5>
                      <p>
                        Rigorous security protocols are embedded in each
                        development hierarchy.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100 ">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Databases Availability</h5>
                      <p>
                        Optimized database model for seamless availability &
                        improved data integrity.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Maintenance Windows</h5>
                      <p>
                        Scheduled maintenance ensures system balance without
                        interrupting user activity.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        {/* try Virtue Life today section */}
        <section className="try-virtue-life-today-section">
          <div className="container">
            <div className="try-virtue-life-today-card">
              <div className="try-virtue-life-today-content">
                <p className="try-virtue-life-today-heading">Try Virtue Life Today</p>
                <p className="try-virtue-life-today-text">Ready to get started?</p>
                <div className="store-name mt-4">
                  <a
                    href="https://physiotherapist.virtuelife.ai/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="px-4">
                      <span>Book a FREE Trial</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>{" "}
      </Layout>
    </>
  );
};

export default SecurityReliability;
