import React from "react";
import Layout from "../../components/layout/Layout";
import "../../assets/css/ContactUs.css";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <meta name="keywords" content="Contact Virtue Life, contact Us" />
        <meta
          name="description"
          content="Request a free quote from Virtue Life today! Contact us to learn more about our services and how we can meet your health and wellness needs."
        />
        <title>Get or Request A Free Quot | Contact Virtue Life</title>
      </Helmet>
      <Layout>
        {/* Contact Us section */}
        <section className="contact-us-section">
          <div className="container">
            <div className="contact-us-content">
              <h1>Contact US</h1>
            </div>
            <form className="form-section">
              <div>
                <div className="form-row">
                  <div className="form-group">
                    <label className="label">First Name</label>
                    <input type="text" placeholder="" className="input" />
                  </div>
                  <div className="form-group ">
                    <label className="label">Last Name</label>
                    <input type="text" placeholder="" className="input" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label className="label">E-mail</label>
                    <input type="text" placeholder="" className="input" />
                  </div>
                  <div className="form-group">
                    <label className="label">Phone</label>
                    <input type="text" placeholder="" className="input" />
                  </div>
                </div>
                <div>
                  <label className="label">Message</label>
                  <input
                    type="text"
                    placeholder=""
                    className="message-container"
                  />
                  <div className="text-center">
                    <button className="submit-button-style">Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>

        {/* General enquiries section */}
        <section className="general-enquiries-section">
          <div className="container">
            <div className="general-enquiries-content">
              <p className="general-enquiries-heading">General enquiries</p>
              <p className="general-enquiries-text">
                For general queries, including partnership opportunities, please
                email
              </p>
              <p className="general-enquiries-highlighted-text">
                support@virtuelife.ai
              </p>
            </div>{" "}
          </div>
        </section>
      </Layout>
    </>
  );
};

export default ContactUs;
