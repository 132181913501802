import React, { useEffect, useState } from "react";
import { Container, Button, Nav, Navbar, Spinner } from "react-bootstrap";
import footerLogo from "../../assets/images/footer-logo.svg";
import headerLogoBlack from "../../assets/images/header-logo-black.svg";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import "../../assets/css/Layout.css";
import { Popover, OverlayTrigger } from "react-bootstrap";
import logoDeveloperInformation from "../../assets/images/logo-developer-information.svg";
import logoContactUs from "../../assets/images/logo-contact-us.svg";
import logoBlog from "../../assets/images/logo-blog.svg";
import logoPhysio from "../../assets/images/logo-physio.svg";
import logoPatient from "../../assets/images/logo-patient.svg";
import logoPatientEngagement from "../../assets/images/logo-patient-engagement.svg";
import logoHomeExerciseLibrary from "../../assets/images/logo-home-exercise-library.svg";
import logoHEPFeatures from "../../assets/images/logo-hep-features.svg";
import logoVirtueLifeBadge from "../../assets/images/logo-virtue-life-badge.svg";
import logoHealthcareSystem from "../../assets/images/logo-healthcare-systems.svg";
import logoSecurityReliability from "../../assets/images/logo-security-reliability.svg";

function Layout(props) {
  const [scrolled, setScrolled] = useState(false);
  const [email, setEmail] = useState("");
  const [subscriptionMessage, setSubscriptionMessage] = useState("");
  const [Loading, setLoading] = useState(false); // State for loader
  const navigate = useNavigate();
  const location = useLocation();
  const [showPopover, setShowPopover] = useState({
    platform: false,
    solutions: false,
    about: false,
  });

  const handleMouseEnter = (type) => {
    setShowPopover((prev) => ({ ...prev, [type]: true }));
  };

  const handleMouseLeave = (type) => {
    setShowPopover((prev) => ({ ...prev, [type]: false }));
  };

  const popover1 = (
    <Popover
      id="popover1"
      onMouseEnter={() => handleMouseEnter("solutions")}
      onMouseLeave={() => handleMouseLeave("solutions")}
    >
      <div className="solutions-popup-card" style={{ height: "fit-content" }}>
        <div className="solutions-popup-card-up">
          <Link to="/virtuelifebadge" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoVirtueLifeBadge}
                    alt="Private Practice logo"
                    width={21.6}
                    height={17.87}
                  />
                </div>
                <p className="popup-logo-text ms-2">Virtue Life Badge</p>
              </div>
              <p className="popup-text-style">
                Earn recognition based on your progress with a personalized
                Virtue Life badge.
              </p>
            </div>
          </Link>
          <div className="popup-vertical-spacer">
            <div className="popup-vertical-divider" />
          </div>
          <Link to="/healthcaresystems" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoHealthcareSystem}
                    alt="Healthcare Systems logo"
                    width={15.37}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Healthcare Systems</p>
              </div>
              <p className="popup-text-style">
                Easy integration with healthcare systems for streamlined patient
                management.
              </p>
            </div>
          </Link>
          <div className="popup-vertical-spacer">
            <div className="popup-vertical-divider" />
          </div>
          <Link to="/hepfeatures" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoHEPFeatures}
                    alt="HEP Features logo"
                    width={8.92}
                    height={21.5}
                  />
                </div>
                <p className="popup-logo-text ms-2">HEP Features</p>
              </div>
              <p className="popup-text-style">
                Unlock modern Home Exercise Program (HEP) tools with real-time
                tracking.
              </p>
            </div>
          </Link>
        </div>
        <div className="solutions-popup-card-down mt-3">
          <Link to="/homeexerciselibrary" className="popup-card-link">
            <div className="popup-card ">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoHomeExerciseLibrary}
                    alt="Home Exercise Library logo"
                    width={12.16}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Home Exercise Library</p>
              </div>
              <p className="popup-text-style">
                Get access to 2000+ home exercise videos for seamless patient
                recovery.
              </p>
            </div>
          </Link>
          <div className="popup-vertical-spacer">
            <div className="popup-vertical-divider" />
          </div>
          <Link to="/patientengagement" className="popup-card-link">
            <div className="popup-card" style={{ marginRight: "-40px" }}>
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoPatientEngagement}
                    alt="Patient Engagement logo"
                    width={15.87}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Patient Engagement</p>
              </div>
              <p className="popup-text-style">
                Enhance patient interaction with customized healthcare with
                timely updates.
              </p>
            </div>
          </Link>
        </div>
      </div>
    </Popover>
  );

  const popover2 = (
    <Popover
      id="popover2"
      onMouseEnter={() => handleMouseEnter("platform")}
      onMouseLeave={() => handleMouseLeave("platform")}
    >
      <div className="platform-popup-card" style={{ height: "fit-content" }}>
        <Link to="/physiotherapist" className="popup-card-link">
          <div className="popup-card">
            <div className="d-flex">
              <img
                src={logoPhysio}
                alt="Physiotherapist logo"
                width={30}
                height={30}
              />
              <p className="popup-logo-text ms-2 mt-1">Physiotherapist</p>
            </div>
            <p className="popup-text-style">
              Offer custom therapy plans via video guides & personalized
              exercises.
            </p>
            <p className="link-text">Learn More</p>
          </div>{" "}
        </Link>
        <div className="popup-vertical-spacer">
          <div className="popup-vertical-divider" />
        </div>
        <Link to="/patient" className="popup-card-link">
          <div className="popup-card">
            <div className="d-flex">
              <img
                src={logoPatient}
                alt="Patient logo"
                width={30}
                height={30}
              />
              <p className="popup-logo-text ms-2 mt-1">Patient</p>
            </div>
            <p className="popup-text-style">
              Grab personalized patient care with easy & trackable home workout
              plans.
            </p>
            <p className="link-text">Learn More</p>
          </div>
        </Link>
        <div className="popup-vertical-spacer">
          <div className="popup-vertical-divider" />
        </div>
        <Link to="/clinic" className="popup-card-link">
          <div className="popup-card">
            <div className="d-flex">
              <img src={logoPhysio} alt="Clinic logo" width={30} height={30} />
              <p className="popup-logo-text ms-2 mt-1">Clinic</p>
            </div>
            <p className="popup-text-style">
              Simplify clinical functioning by offering enhanced care using
              digital tools.
            </p>
            <p className="link-text">Learn More</p>
          </div>
        </Link>
      </div>
    </Popover>
  );

  const popover3 = (
    <Popover
      id="popover3"
      onMouseEnter={() => handleMouseEnter("about")}
      onMouseLeave={() => handleMouseLeave("about")}
    >
      <div className="about-popup-card" style={{ height: "fit-content" }}>
        <div className="about-popup-card-up">
          <Link to="/aboutvirtuelife" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoPhysio}
                    alt="Virtue Life logo"
                    width={18}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">About Virtue Life</p>
              </div>
              <p className="popup-text-style">
                A patient-centric platform leveraging patient care via tailored
                exercise programs.
              </p>
            </div>{" "}
          </Link>
          <div className="popup-vertical-spacer">
            <div className="popup-vertical-divider" />
          </div>
          <Link to="/securityreliability" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoSecurityReliability}
                    alt="Security & Trust logo"
                    width={15.59}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Security & Reliability</p>
              </div>
              <p className="popup-text-style">
                HIPAA-compliance-based encrypted systems ensure reliable patient
                data handling.
              </p>
            </div>{" "}
          </Link>
          <div className="popup-vertical-spacer">
            <div className="popup-vertical-divider" />
          </div>
          <Link to="/developerinformation" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoDeveloperInformation}
                    alt="Developer Information logo"
                    width={15.76}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Developer Information</p>
              </div>
              <p className="popup-text-style">
              Access API documentation, integration guides, and tools to seamlessly connect your platform with VirtueLife.
              </p>
            </div>
          </Link>
        </div>
        <div className="about-popup-card-down mt-3">
          <Link to="/contactus" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoContactUs}
                    alt="Contact Us logo"
                    width={15.21}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Contact Us</p>
              </div>
            </div>
          </Link>
          <Link to="/blog" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img src={logoBlog} alt="Blog logo" width={17} height={17} />
                </div>
                <p className="popup-logo-text ms-2">Blog</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </Popover>
  );
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setSubscriptionMessage("");
  };

  const handleSubscribe = async () => {
    if (!email) {
      setSubscriptionMessage("Please enter an email address.");
      return;
    }
    if (!validateEmail(email)) {
      setSubscriptionMessage("Please enter a valid email address.");
      return;
    }
    setLoading(true); // Show loader when the process starts

    try {
      const response = await axios.post("https://api.virtuelife.ai/subscribe", {
        email,
      });
      setSubscriptionMessage(response.data.msg); // 'Successfully subscribed'
      setEmail(""); // Clear the email input field after successful subscription
    } catch (error) {
      setSubscriptionMessage(error.response.data.error || "An error occurred");
    } finally {
      setLoading(false); // Hide loader when the process finishes
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const isHeaderSection = location.pathname === "/pricing";
  // Apply conditional styles
  const headerStyle = {
    backgroundColor: isHeaderSection ? "#ffede8" : "#ffffff",
  };

  const isNavbarSection = location.pathname === "/pricing";
  // Apply conditional styles
  const navbarStyle = {
    backgroundColor: isNavbarSection ? "#ffede8" : "initial",
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.children]);
  return (
    <>
      <main className="main-content-part">
        {/* header section */}
        <header
          className={`${scrolled ? "scrolled" : ""} header-part`}
          style={headerStyle}
        >
          <Navbar expand="md">
            <Container>
              <Navbar.Brand
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/");
                }}
              >
                <img src={headerLogoBlack} alt="Logo" width="191" height="50" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className={`${scrolled ? "scrolled" : ""} navbar-part`}
                style={navbarStyle}
              >
                <Nav>
                  <OverlayTrigger
                    trigger="hover"
                    placement="bottom"
                    show={showPopover.solutions}
                    overlay={popover1}
                  >
                    <div className="flex-row-container">
                      <div>
                        <div
                          href=""
                          className="navbar-nav-link"
                          onMouseEnter={() => handleMouseEnter("solutions")}
                          onMouseLeave={() => handleMouseLeave("solutions")}
                        >
                          Solutions
                        </div>
                      </div>
                      <div className="svg-container-home">
                        <svg
                          fill="none"
                          viewBox="0 0 10 10"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0"
                          y="0"
                        >
                          <path
                            id="Vector 14"
                            d="M1,3l4,4l4,-4"
                            stroke="black"
                            xmlns="http://www.w3.org/2000/svg"
                          />
                        </svg>
                      </div>
                    </div>
                  </OverlayTrigger>
                  <div className="ms-2">
                    <Link
                      to="/virtuelifebadge"
                      className="mobile-view mobile-display"
                    >
                      Virtue Life Badge
                    </Link>
                    <Link
                      to="/healthcaresystems"
                      className="mobile-view mobile-display"
                    >
                      Healthcare Systems
                    </Link>
                    <Link
                      to="/hepfeatures"
                      className="mobile-view mobile-display"
                    >
                      HEP Features
                    </Link>
                    <Link
                      to="/homeexerciselibrary"
                      className="mobile-view mobile-display"
                    >
                      Home Exercise Library
                    </Link>
                    <Link
                      to="/patientengagement"
                      className="mobile-view mobile-display"
                    >
                      Patient Engagement
                    </Link>
                  </div>
                  <OverlayTrigger
                    trigger="hover"
                    placement="bottom"
                    show={showPopover.platform}
                    overlay={popover2}
                  >
                    <div className="flex-row-container">
                      <div>
                        <div
                          href=""
                          className="navbar-nav-link"
                          onMouseEnter={() => handleMouseEnter("platform")}
                          onMouseLeave={() => handleMouseLeave("platform")}
                        >
                          Platform
                        </div>
                      </div>
                      <div className="svg-container-home">
                        <svg
                          fill="none"
                          viewBox="0 0 10 10"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0"
                          y="0"
                        >
                          <path
                            id="Vector 14"
                            d="M1,3l4,4l4,-4"
                            stroke="black"
                            xmlns="http://www.w3.org/2000/svg"
                          />
                        </svg>
                      </div>
                    </div>
                  </OverlayTrigger>
                  <div className="ms-2">
                    <Link
                      to="/physiotherapist"
                      className="mobile-view mobile-display"
                    >
                      Physiotherapist{" "}
                    </Link>
                    <Link to="/patient" className="mobile-view mobile-display">
                      Patient{" "}
                    </Link>
                    <Link to="/clinic" className="mobile-view mobile-display">
                      Clinic{" "}
                    </Link>
                  </div>
                  <OverlayTrigger
                    trigger="hover"
                    placement="bottom"
                    show={showPopover.about}
                    overlay={popover3}
                  >
                    <div className="flex-row-container">
                      <div>
                        <div
                          href=""
                          className="navbar-nav-link"
                          onMouseEnter={() => handleMouseEnter("about")}
                          onMouseLeave={() => handleMouseLeave("about")}
                        >
                          About
                        </div>
                      </div>
                      <div className="svg-container-home">
                        <svg
                          fill="none"
                          viewBox="0 0 10 10"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0"
                          y="0"
                        >
                          <path
                            id="Vector 14"
                            d="M1,3l4,4l4,-4"
                            stroke="black"
                            xmlns="http://www.w3.org/2000/svg"
                          />
                        </svg>
                      </div>
                    </div>
                  </OverlayTrigger>
                  <div className="ms-2">
                    <Link
                      to="/aboutvirtuelife"
                      className="mobile-view mobile-display"
                    >
                      About Virtue Life{" "}
                    </Link>
                    <Link
                      to="/securityreliability"
                      className="mobile-view mobile-display"
                    >
                      Security & Reliability{" "}
                    </Link>
                    <Link
                      to="/developerinformation"
                      className="mobile-view mobile-display"
                    >
                      Developer Information{" "}
                    </Link>
                    <Link
                      to="/contactus"
                      className="mobile-view mobile-display"
                    >
                      Contact us{" "}
                    </Link>
                    <Link to="/blog" className="mobile-view mobile-display">
                      Blog{" "}
                    </Link>
                  </div>
                  <Nav.Link as={Link} to="/pricing" className="mt-1">
                    Pricing
                  </Nav.Link>
                  <Nav.Link as={Link} to="/blog" className="mt-1">
                    Blog
                  </Nav.Link>
                  <hr />
                  <Nav.Link
                    href="#feature-section"
                    className="mobile-display"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://physiotherapist.virtuelife.com/login"
                      );
                    }}
                  >
                    Log in
                  </Nav.Link>
                  <Nav.Link
                    href="#benifit-section"
                    className="mobile-display"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://physiotherapist.virtuelife.ai/login"
                      );
                    }}
                  >
                    Try for Free{" "}
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
              <div className="d-flex deskto-display">
                <button
                  className="me-2 header-outline-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      "https://physiotherapist.virtuelife.ai/login",
                      "_blank"
                    );
                  }}
                >
                  Log in
                </button>{" "}
                <button
                  className="me-2 header-outline-btn-free"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      "https://physiotherapist.virtuelife.ai/login",
                      "_blank"
                    );
                  }}
                >
                  <span>Try for Free </span>
                </button>{" "}
              </div>
            </Container>
          </Navbar>
        </header>
        <hr style={{ color: "black" }} />

        <div>
          <div className="content">{props.children}</div>
        </div>

        <footer className="footer-section">
          <section className="register-section">
            <Container>
              <div>
                <div className="footer-coppy-part pb-5">
                  <div className="email-box d-flex">
                    <h2 className="email-content-part">
                      Ready to experience our features? It's just a matter of
                      one click
                    </h2>
                    <div className="email-part">
                      <input
                        type="email"
                        className="email-input text-white"
                        placeholder="Write email address"
                        value={email}
                        onChange={handleEmailChange}
                        style={{
                          backgroundColor: "rgb(49, 46, 46)",
                          border: "0px",
                        }}
                      />
                      <button
                        type="button"
                        className="sub-button btn btn-primary"
                        onClick={handleSubscribe}
                        disabled={Loading} // Disable button during loadin
                      >
                        {Loading ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Subscribe"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  {subscriptionMessage && (
                    <p className="subscription">{subscriptionMessage}</p>
                  )}
                </div>
              </div>
            </Container>
            <hr className="horizontal-line" />
          </section>
          <Container>
            <footer className="footer">
              <div className="container">
                <div className="row">
                  <div className="footer-col">
                    <a href="/">
                      <img alt="" src={footerLogo} style={{ height: "35px" }} />
                    </a>
                    <p className="mt-4">Together, Towards Better Health</p>
                    <div className="social-links">
                      <a
                        href="https://www.instagram.com/virtuelife_tech/"
                        target="_blank"
                      >
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a href="https://x.com/virtuelife_tech" target="_blank">
                        <i className="bi bi-twitter"></i>
                      </a>
                      <a
                        href="https://www.facebook.com/profile.php?id=61557263103084"
                        target="_blank"
                      >
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/virtuelife_tech/"
                        target="_blank"
                      >
                        <i className="bi bi-linkedin"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/@virtuelife_tech"
                        target="_blank"
                      >
                        <i className="bi bi-youtube"></i>
                      </a>
                    </div>
                  </div>

                  <div className="footer-col-platform">
                    <h4>Platform</h4>
                    <ul className="p-0">
                      <li>
                        <Link to="/patient">Patient</Link>
                      </li>
                      <li>
                        <Link to="/physiotherapist">Physiotherapist</Link>
                      </li>
                      <li>
                        <Link to="/clinic">Clinic</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-col-support">
                    <h4>Solutions</h4>
                    <ul className="p-0">
                      <li>
                        <Link to="/healthcaresystems">Healthcare Systems</Link>
                      </li>
                      <li>
                        <Link to="/homeexerciselibrary">
                          Home Exercise Library
                        </Link>
                      </li>
                      <li>
                        <Link to="/patientengagement">Patient Engagement</Link>
                      </li>
                      <li>
                        <Link to="/hepfeatures">HEP Features</Link>
                      </li>
                      <li>
                        <Link to="/virtuelifebadge">
                          Virtue Life Certified Badge
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-col-solution">
                    <h4>About</h4>
                    <ul className="p-0">
                      <li>
                        <Link to="/aboutvirtuelife">About Virtue Life</Link>
                      </li>
                      <li>
                        <Link to="/contactus">Contact us</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/securityreliability">
                          Security & Reliability
                        </Link>
                      </li>
                      <li>
                        <Link to="/developerinformation">
                          Developer Information
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <p style={{ fontSize: "14px" }}>
                  Copyright 2024 @VirtueLife <br />
                  Virtue Life is a fitness and wellness tool and does not
                  guarantee outcomes in a rehabilitation process. For more
                  information, contact your healthcare provider.
                </p>
              </div>
            </footer>
          </Container>
        </footer>
      </main>
    </>
  );
}
export default Layout;
