import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/Layout.css";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import "../../assets/css/Home.css";
import physioLaptopImage from "../../assets/images/home-laptop.png";
import a from "../../assets/images/a.png";
import b from "../../assets/images/b.png";
import streamlinedPatient1 from "../../assets/images/streamlined-patient1.gif";
import streamlinedPatient2 from "../../assets/images/streamlined-patient2.gif";
import streamlinedPatient3 from "../../assets/images/streamlined-patient3.gif";
import streamlinedPatient4 from "../../assets/images/streamlined-patient4.gif";
import streamlinedPatient5 from "../../assets/images/streamlined-patient5.gif";
import streamlinedPatient6 from "../../assets/images/streamlined-patient6.gif";
import headerLogoWhite from "../../assets/images/header-logo-white.svg";
import footerLogo from "../../assets/images/footer-logo.svg";
import axios from "axios";
import Testimonial from "../../components/testimonial/Testimonial";
import logoDeveloperInformation from "../../assets/images/logo-developer-information.svg";
import logoContactUs from "../../assets/images/logo-contact-us.svg";
import logoBlog from "../../assets/images/logo-blog.svg";
import logoPhysio from "../../assets/images/logo-physio.svg";
import logoPatient from "../../assets/images/logo-patient.svg";
import logoPatientEngagement from "../../assets/images/logo-patient-engagement.svg";
import logoHomeExerciseLibrary from "../../assets/images/logo-home-exercise-library.svg";
import logoHEPFeatures from "../../assets/images/logo-hep-features.svg";
import logoVirtueLifeBadge from "../../assets/images/logo-virtue-life-badge.svg";
import logoHealthcareSystem from "../../assets/images/logo-healthcare-systems.svg";
import logoSecurityReliability from "../../assets/images/logo-security-reliability.svg";
import { Popover, OverlayTrigger, Button } from "react-bootstrap";
import PhysioAnimation from "../../components/physioAnimation/PhysioAnimation";
import animation1 from "../../assets/images/Animation1.png";
import animation2 from "../../assets/images/Animation2.png";
import animation3 from "../../assets/images/Animation3.png";
import "../../assets/css/Layout.css";
import { Helmet } from "react-helmet";
import blogimage from  "../../assets/images/Img1-min.png"
import blogimage2 from  "../../assets/images/Img2-min.png"
import blogimage3 from  "../../assets/images/Img3-min.png"
import blogimage4 from  "../../assets/images/Img4-min.png"

const sectionsData = [
  {
    logo: (
      <div className="d-flex">
        <img
          src={logoPhysio}
          alt="Physiotherapist logo"
          width={30}
          height={30}
        />
        <h4 className="physio-logo-text ms-2 mt-1">Physiotherapist</h4>
      </div>
    ),
    navigateTo: "/physiotherapist",
    title: "Tailored Exercise Plan For Smoother Recovery",
    description: (
      <span>
        At Virtue Life, we have professionally trained physiotherapists to
        understand your restoration needs and make personalized{" "}
        <strong>physiotherapy exercises</strong> for sooner recovery. Get your
        recovery done using the best <strong>PT therapy </strong>
        by our trusted and qualified physiotherapists.
      </span>
    ),
    image: animation1,
    imageAlt: "Feature banner showcasing exercise prescription",
    extraContent: [
      {
        highlighted: "PT Therapy Programs:",
        text: "Hand-picked pelvic healthcare exercises.",
      },
      {
        highlighted: "Custom Rehab Plans:",
        text: "Customized rehab exercise routines.",
      },
      {
        highlighted: "Home Exercise Therapy:",
        text: "Exercises for quick recovery.",
      },
    ],
  },
  {
    logo: (
      <div className="d-flex">
        <img src={logoPhysio} alt="Clinic logo" width={30} height={30} />
        <h4 className="clinic-logo-text ms-2 mt-1">Clinic</h4>
      </div>
    ),
    logoAlt: "Clinic logo",
    title: "2000+ Exercise Videos & 200+ Protocols",
    navigateTo: "/clinic",
    description:
      "Get a huge variety of exercise prescriptions with 2000+ exercise videos & 200+ step-by-step protocols to choose from. Select one that best matches your restoration needs and the steps most suited to offer a world-class clinical experience.",
    image: animation2,
    imageAlt: "Feature banner showcasing exercise videos",
    extraContent: [
      {
        highlighted: "Exercise Integration:",
        text: "Seamless exercise models.",
      },
      {
        highlighted: "Patient Data Management:",
        text: "Centralized record monitoring.",
      },
      {
        highlighted: "Performance Reports:",
        text: "Track your daily progress.",
      },
    ],
  },
  {
    logo: (
      <div className="d-flex">
        <img src={logoPatient} alt="Patient logo" width={30} height={30} />
        <h4 className="patient-logo-text ms-2 mt-1">Patient</h4>
      </div>
    ),
    logoAlt: "Patient logo",
    title: "End-to-End Patient Care To Meet Your Needs!",
    navigateTo: "/patient",
    description: (
      <span>
        Being the{" "}
        <strong> Best Physical Therapist Software in the USA - </strong>Virtue
        Life offers a wide range of professionally trained physiotherapists with
        endless home <strong>workout programs </strong>customized to your
        restoration needs. The platform also possesses exercise programs with
        reliable steps, pain-level tracking, and intensive care.
      </span>
    ),
    image: animation3,
    imageAlt: "Feature banner showcasing patient exercises",
    extraContent: [
      {
        highlighted: "Personalized Home Workouts:",
        text: "Tailored exercise routines.",
      },
      {
        highlighted: "Pain & Progress Monitoring:",
        text: "Easy way to check your health status.",
      },
      {
        highlighted: "Flexible Doctor Appointments:",
        text: "Book your physio session.",
      },
    ],
  },
];

const Main = () => {
  const [communitiesList, setcommunitiesList] = useState({
    items: [],
    has_more: false,
    totalPages: 0,
  });
  const [Loading, setLoading] = useState(false); // State for loader

  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [page, setPage] = useState(1);
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [subscriptionMessage, setSubscriptionMessage] = useState("");
  const [showPopover, setShowPopover] = useState({
    platform: false,
    solutions: false,
    about: false,
  });

  const handleMouseEnter = (type) => {
    setShowPopover((prev) => ({ ...prev, [type]: true }));
  };

  const handleMouseLeave = (type) => {
    setShowPopover((prev) => ({ ...prev, [type]: false }));
  };

  const popover1 = (
    <Popover
      id="popover1"
      onMouseEnter={() => handleMouseEnter("solutions")}
      onMouseLeave={() => handleMouseLeave("solutions")}
    >
      <div className="solutions-popup-card" style={{ height: "fit-content" }}>
        <div className="solutions-popup-card-up">
          <Link to="/virtuelifebadge" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoVirtueLifeBadge}
                    alt="Private Practice logo"
                    width={21.6}
                    height={17.87}
                  />
                </div>
                <p className="popup-logo-text ms-2">Virtue Life Badge</p>
              </div>
              <p className="popup-text-style">
                Earn recognition based on your progress with a personalized
                Virtue Life badge.
              </p>
            </div>
          </Link>
          <div className="popup-vertical-spacer">
            <div className="popup-vertical-divider" />
          </div>
          <Link to="/healthcaresystems" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoHealthcareSystem}
                    alt="Healthcare Systems logo"
                    width={15.37}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Healthcare Systems</p>
              </div>
              <p className="popup-text-style">
                Easy integration with healthcare systems for streamlined patient
                management.
              </p>
            </div>
          </Link>
          <div className="popup-vertical-spacer">
            <div className="popup-vertical-divider" />
          </div>
          <Link to="/hepfeatures" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoHEPFeatures}
                    alt="HEP Features logo"
                    width={8.92}
                    height={21.5}
                  />
                </div>
                <p className="popup-logo-text ms-2">HEP Features</p>
              </div>
              <p className="popup-text-style">
                Unlock modern Home Exercise Program (HEP) tools with real-time
                tracking.
              </p>
            </div>
          </Link>
        </div>
        <div className="solutions-popup-card-down mt-3">
          <Link to="/homeexerciselibrary" className="popup-card-link">
            <div className="popup-card ">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoHomeExerciseLibrary}
                    alt="Home Exercise Library logo"
                    width={12.16}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Home Exercise Library</p>
              </div>
              <p className="popup-text-style">
                Get access to 2000+ home exercise videos for seamless patient
                recovery.
              </p>
            </div>
          </Link>
          <div className="popup-vertical-spacer">
            <div className="popup-vertical-divider" />
          </div>
          <Link to="/patientengagement" className="popup-card-link">
            <div className="popup-card" style={{ marginRight: "-40px" }}>
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoPatientEngagement}
                    alt="Patient Engagement logo"
                    width={15.87}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Patient Engagement</p>
              </div>
              <p className="popup-text-style">
                Enhance patient interaction with customized healthcare with
                timely updates.
              </p>
            </div>
          </Link>
        </div>
      </div>
    </Popover>
  );

  const popover2 = (
    <Popover
      id="popover2"
      onMouseEnter={() => handleMouseEnter("platform")}
      onMouseLeave={() => handleMouseLeave("platform")}
    >
      <div className="platform-popup-card" style={{ height: "fit-content" }}>
        <Link to="/physiotherapist" className="popup-card-link">
          <div className="popup-card">
            <div className="d-flex">
              <img
                src={logoPhysio}
                alt="Physiotherapist logo"
                width={30}
                height={30}
              />
              <p className="popup-logo-text ms-2 mt-1">Physiotherapist</p>
            </div>
            <p className="popup-text-style">
              Offer custom therapy plans via video guides & personalized
              exercises.
            </p>
            <p className="link-text">Learn More</p>
          </div>{" "}
        </Link>
        <div className="popup-vertical-spacer">
          <div className="popup-vertical-divider" />
        </div>
        <Link to="/patient" className="popup-card-link">
          <div className="popup-card">
            <div className="d-flex">
              <img
                src={logoPatient}
                alt="Patient logo"
                width={30}
                height={30}
              />
              <p className="popup-logo-text ms-2 mt-1">Patient</p>
            </div>
            <p className="popup-text-style">
              Grab personalized patient care with easy & trackable home workout
              plans.
            </p>
            <p className="link-text">Learn More</p>
          </div>
        </Link>
        <div className="popup-vertical-spacer">
          <div className="popup-vertical-divider" />
        </div>
        <Link to="/clinic" className="popup-card-link">
          <div className="popup-card">
            <div className="d-flex">
              <img src={logoPhysio} alt="Clinic logo" width={30} height={30} />
              <p className="popup-logo-text ms-2 mt-1">Clinic</p>
            </div>
            <p className="popup-text-style">
              Simplify clinical functioning by offering enhanced care using
              digital tools.
            </p>
            <p className="link-text">Learn More</p>
          </div>
        </Link>
      </div>
    </Popover>
  );

  const popover3 = (
    <Popover
      id="popover3"
      onMouseEnter={() => handleMouseEnter("about")}
      onMouseLeave={() => handleMouseLeave("about")}
    >
      <div className="about-popup-card" style={{ height: "fit-content" }}>
        <div className="about-popup-card-up">
          <Link to="/aboutvirtuelife" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoPhysio}
                    alt="Virtue Life logo"
                    width={18}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">About Virtue Life</p>
              </div>
              <p className="popup-text-style">
                A patient-centric platform leveraging patient care via tailored
                exercise programs.
              </p>
            </div>{" "}
          </Link>
          <div className="popup-vertical-spacer">
            <div className="popup-vertical-divider" />
          </div>
          <Link to="/securityreliability" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoSecurityReliability}
                    alt="Security & Trust logo"
                    width={15.59}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Security & Reliability</p>
              </div>
              <p className="popup-text-style">
                HIPAA-compliance-based encrypted systems ensure reliable patient
                data handling.
              </p>
            </div>{" "}
          </Link>
          <div className="popup-vertical-spacer">
            <div className="popup-vertical-divider" />
          </div>
          <Link to="/developerinformation" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoDeveloperInformation}
                    alt="Developer Information logo"
                    width={15.76}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Developer Information</p>
              </div>
              <p className="popup-text-style">
              Access API documentation, integration guides, and tools to seamlessly connect your platform with VirtueLife.
              </p>
            </div>
          </Link>
        </div>
        <div className="about-popup-card-down mt-3">
          <Link to="/contactus" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoContactUs}
                    alt="Contact Us logo"
                    width={15.21}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Contact Us</p>
              </div>
            </div>
          </Link>
          <Link to="/blog" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img src={logoBlog} alt="Blog logo" width={17} height={17} />
                </div>
                <p className="popup-logo-text ms-2">Blog</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </Popover>
  );

  const handleSubscribe = async () => {
    if (!email) {
      setSubscriptionMessage("Please enter an email address.");
      return;
    }
    if (!validateEmail(email)) {
      setSubscriptionMessage("Please enter a valid email address.");
      return;
    }
    setLoading(true); // Show loader when the process starts

    try {
      const response = await axios.post("https://api.virtuelife.ai/subscribe", {
        email,
      });
      setSubscriptionMessage(response.data.msg); // 'Successfully subscribed'
      setEmail(""); // Clear the email input field after successful subscription
    } catch (error) {
      setSubscriptionMessage(error.response.data.error || "An error occurred");
    } finally {
      setLoading(false); // Hide loader when the process finishes
    }
  };
  useEffect(() => {
    getCommunitiesList();
  }, [page]);
  async function getCommunitiesList() {
    setIsLoading(true); // Start loading
    try {
      let url = `https://api.virtuelife.ai/blogList?pageNumber=${page}&myCommunity=true`;
      const data = await axios.get(url);
      if (data?.status === 200) {
        if (page > 1) {
          setcommunitiesList((p) => ({
            ...p,
            items: communitiesList?.items.concat(data?.data?.communities),
          }));
        } else {
          setcommunitiesList((p) => ({ ...p, items: data?.data?.communities }));
        }
        // setcommunitiesList((e) => ({ ...e, items: data?.data?.data }))
        setcommunitiesList((e) => ({
          ...e,
          has_more: data?.data?.has_more,
          totalPages: data?.data?.totalPages,
        }));
      }
    } catch (error) {}
    setIsLoading(false); // Stop loading
  }
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Physiotherapy Exercise, Physical Therapist , PT Therapy, Physical Therapist Software, Online Physical Therapy, Workout Program"
        />
        <meta
          name="description"
          content="Discover the best physical therapist software in the USA for online therapy and home exercise programs. Streamline care and enhance patient outcomes today!"
        />
        <title>
          Best Physical Therapist Software in USA | Online Physical Therapy |
          Home Exersice Program
        </title>
      </Helmet>
      {/* header and banner section */}
      <div className="header-banner-section">
        <section className="header-banner-background"> </section>
        <div className="header-banner-content">
          <header className={`${scrolled ? "scrolled" : ""} header-part`}>
            <nav className="navbar navbar-expand-md navbar-light">
              <div className="container">
                <span
                  className="navbar-brand"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/");
                  }}
                >
                  <img
                    src={headerLogoWhite}
                    alt="Logo"
                    width="191"
                    height="50"
                  />
                </span>
                <button
                  aria-controls="basic-navbar-nav"
                  type="button"
                  aria-label="Toggle navigation"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                  data-bs-target="#basic-navbar-nav"
                  className="navbar-toggler collapsed"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="navbar-part navbar-collapse collapse"
                  id="basic-navbar-nav"
                  style={{ backgroundColor: "initial" }}
                >
                  <div className="navbar-nav">
                    <OverlayTrigger
                      trigger="hover"
                      placement="bottom"
                      show={showPopover.solutions}
                      overlay={popover1}
                    >
                      <div className="flex-row-home-container">
                        <div>
                          <div
                            href=""
                            className="home-navbar-nav-link"
                            onMouseEnter={() => handleMouseEnter("solutions")}
                            onMouseLeave={() => handleMouseLeave("solutions")}
                          >
                            Solutions
                          </div>
                        </div>
                        <div className="svg-container-home">
                          <svg
                            fill="none"
                            viewBox="0 0 10 10"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0"
                            y="0"
                          >
                            <path
                              id="Vector 14"
                              d="M1,3l4,4l4,-4"
                              stroke="white"
                              xmlns="http://www.w3.org/2000/svg"
                            />
                          </svg>
                        </div>
                      </div>
                    </OverlayTrigger>

                    <div className="ms-2">
                      <Link
                        to="/virtuelifebadge"
                        className="mobile-view mobile-display"
                        style={{ color: "white" }}

                      >
                        Virtue Life Badge
                      </Link>
                      <Link
                        to="/healthcaresystems"
                        className="mobile-view mobile-display"
                        style={{ color: "white" }}
                      >
                        Healthcare Systems
                      </Link>
                      <Link
                        to="/hepfeatures"
                        className="mobile-view mobile-display"
                        style={{ color: "white" }}
                      >
                        HEP Features
                      </Link>
                      <Link
                        to="/homeexerciselibrary"
                        className="mobile-view mobile-display"
                        style={{ color: "white" }}
                      >
                        Home Exercise Library
                      </Link>
                      <Link
                        to="/patientengagement"
                        className="mobile-view mobile-display"
                        style={{ color: "white" }}
                      >
                        Patient Engagement
                      </Link>
                    </div>

                    <OverlayTrigger
                      trigger="hover"
                      placement="bottom"
                      show={showPopover.platform}
                      overlay={popover2}
                    >
                      <div className="flex-row-home-container">
                        <div>
                          <div
                            href=""
                            className="home-navbar-nav-link"
                            onMouseEnter={() => handleMouseEnter("platform")}
                            onMouseLeave={() => handleMouseLeave("platform")}
                          >
                            Platform
                          </div>
                        </div>
                        <div className="svg-container-home">
                          <svg
                            fill="none"
                            viewBox="0 0 10 10"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0"
                            y="0"
                          >
                            <path
                              id="Vector 14"
                              d="M1,3l4,4l4,-4"
                              stroke="white"
                              xmlns="http://www.w3.org/2000/svg"
                            />
                          </svg>
                        </div>
                      </div>
                    </OverlayTrigger>

                    <div className="ms-2">
                      <Link
                        to="/physiotherapist"
                        className="mobile-view mobile-display"
                        style={{ color: "white" }}
                      >
                        Physiotherapist{" "}
                      </Link>
                      <Link
                        to="/patient"
                        className="mobile-view mobile-display"
                        style={{ color: "white" }}
                      >
                        Patient{" "}
                      </Link>
                      <Link
                        to="/clinic"
                        className="mobile-view mobile-display"
                        style={{ color: "white" }}
                      >
                        Clinic{" "}
                      </Link>
                    </div>

                    <OverlayTrigger
                      trigger="hover"
                      placement="bottom"
                      show={showPopover.about}
                      overlay={popover3}
                    >
                      <div className="flex-row-home-container">
                        <div>
                          <div
                            href=""
                            className="home-navbar-nav-link"
                            onMouseEnter={() => handleMouseEnter("about")}
                            onMouseLeave={() => handleMouseLeave("about")}
                          >
                            About
                          </div>
                        </div>
                        <div className="svg-container-home">
                          <svg
                            fill="none"
                            viewBox="0 0 10 10"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0"
                            y="0"
                          >
                            <path
                              id="Vector 14"
                              d="M1,3l4,4l4,-4"
                              stroke="white"
                              xmlns="http://www.w3.org/2000/svg"
                            />
                          </svg>
                        </div>
                      </div>
                    </OverlayTrigger>

                    <div className="ms-2">
                      <Link
                        to="/aboutvirtuelife"
                        className="mobile-view mobile-display"
                        style={{ color: "white" }}
                      >
                        About Virtue Life{" "}
                      </Link>
                      <Link
                        to="/securityreliability"
                        className="mobile-view mobile-display"
                        style={{ color: "white" }}
                      >
                        Security & Reliability{" "}
                      </Link>
                      <Link
                        to="/developerinformation"
                        className="mobile-view mobile-display"
                        style={{ color: "white" }}
                      >
                        Developer Information{" "}
                      </Link>
                      <Link
                        to="/contactus"
                        className="mobile-view mobile-display"
                        style={{ color: "white" }}
                      >
                        Contact us{" "}
                      </Link>
                      <Link
                        to="/blog"
                        className="mobile-view mobile-display"
                        style={{ color: "white" }}
                      >
                        Blog{" "}
                      </Link>
                    </div>

                    <Link to="/pricing" className="home-nav-link mt-1">
                      Pricing
                    </Link>
                    <Link to="/blog" className="home-nav-link mt-1">
                      Blog
                    </Link>
                    <hr style={{ color: "white" }} />
                    <a
                      href=""
                      className="home-nav-link mobile-display"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "https://physiotherapist.virtuelife.ai/login"
                        );
                      }}
                    >
                      Log in
                    </a>
                    <a
                      href=""
                      className="home-nav-link mobile-display"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "https://physiotherapist.virtuelife.ai/login"
                        );
                      }}
                    >
                      Try for Free
                    </a>
                  </div>
                </div>
                <div className="d-flex deskto-display">
                  <button
                    className="me-2 home-header-outline-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://physiotherapist.virtuelife.ai/login",
                        "_blank"
                      );
                    }}
                  >
                    Log in
                  </button>
                  <button
                    className="me-2 home-header-outline-btn-free"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://physiotherapist.virtuelife.ai/login",
                        "_blank"
                      );
                    }}
                  >
                    <span>Try for Free</span>
                  </button>
                </div>
              </div>
            </nav>
          </header>
          <hr style={{ color: "white" }} />

          <section className="banner-section position-relative">
            <Container>
              <Row className="my-5 py-4">
                <Col
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  className="d-flex align-items-center"
                >
                  <div className="banner-content">
                    <div className="title-part" style={{ color: "white" }}>
                      <h1>Streamline Your Home Exercise Prescription NOW! </h1>
                      <p style={{ color: "white" }}>
                        Virtue Life offers an instant home exercise prescription
                        curated to meet your fitness and recovery routine. Our
                        plans are simple to assist physiotherapists in
                        streamlining patients' rehabilitation journey.
                      </p>
                    </div>
                    <div className="store-name">
                      <a
                        href="https://physiotherapist.virtuelife.ai/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        <button className="px-4">
                          <span>Book a FREE Trial</span>
                        </button>
                      </a>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="d-flex banner-image">
                    <img
                      src={physioLaptopImage}
                      alt=""
                      height={"100%"}
                      width={"100%"}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </div>

      {/* title-section */}
      <div className="rehab-therapy-banner">
        <div className="rehab-bridge-section">
          <img
            src={a}
            alt="Rehabilitation therapist bridging the gap"
            className="rehab-therapist-image"
          />
          <div className="rehab-therapist-info-card">
            <p className="rehab-therapist-heading">
              Getting Rehabilitation Therapists & Patients in Sync For A Quick
              Recovery{" "}
            </p>
            <div className="rehab-therapist-banner">
              <img
                src={b}
                alt="Rehabilitation therapist banner"
                className="title-part-image-container"
              />
            </div>
          </div>
        </div>
      </div>

      <PhysioAnimation sections={sectionsData} />

      {/* global-scale */}
      <section className="global-scale-section">
        <div className="container">
          <Row className="align-items-center">
            <Col md={6} sm={12}>
              <div className="global-scale-content-part">
                <div className="global-scale-title-part  light-theme">
                  <p>What’s New?</p>
                  <h2>Check Out what Virtue Life Can Offer !</h2>

                  <button className="global-scale-button">
                    <span>Book a FREE Trial</span>
                  </button>
                </div>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div>
                <p className="global-text">
                  The Virtue Life <strong>physical therapy app </strong>is
                  designed to make your recovery quicker with safe and reliable
                  exercise plans. Here are some staggering stats that speak
                  volumes regarding our competence in this evolving & advanced
                  medical sector!
                </p>
                <div className="stats-container">
                  <div className="info-card">
                    <p className="statistic-text">500+</p>
                    <p className="clinic-stats-text-style">Global Clinics</p>
                  </div>
                  <div className="global-scale-vertical-divider" />
                  <div className="info-card">
                    <p className="statistic-text">1200+</p>
                    <p className="clinic-stats-text-style">
                      Healthcare Specialists
                    </p>
                  </div>
                  <div className="global-scale-vertical-divider" />
                  <div className="info-card">
                    <p className="statistic-text">26k+</p>
                    <p className="clinic-stats-text-style">Trusted Patients</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      {/* Streamlined-Patient-section */}
      <section className="streamlined-patient-section">
        <div className="container">
          <div className="center-title-part">
            <h2>Resourceful Patient Supervision & Partnership</h2>
            <p>
              Avail competent patient management and seamless collaboration
              through our platform - Health professionals and patients can seek
              efficacious retrieval programs with{" "}
              <strong> online physical therapy </strong>for sooner recovery.
            </p>
          </div>

          <Row>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="Streamlined-Patient-card">
                <div className="streamlined-patient-image-container">
                  <img
                    src={streamlinedPatient1}
                    className="streamlined-patient-image"
                    alt=""
                  />
                </div>
              </div>
              <div className="streamlined-patient-content">
                <p className="streamlined-patient-heading">
                  Private Practices{" "}
                </p>
                <p className="streamlined-patient-text">
                  Get expert assistance for painless recovery and better
                  treatment care via personalized{" "}
                  <strong>home workout programs </strong>from Virtue Life.
                </p>
                <a
                  href="/"
                  className="streamlined-patient-learn-more"
                  style={{ textDecoration: "underline" }}
                >
                  Learn More
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="Streamlined-Patient-card">
                <div className="streamlined-patient-image-container">
                  <img
                    src={streamlinedPatient2}
                    className="streamlined-patient-image"
                    alt=""
                  />
                </div>
              </div>
              <div className="streamlined-patient-content">
                <p className="streamlined-patient-heading">
                  Healthcare Systems{" "}
                </p>
                <p className="streamlined-patient-text">
                  Get everything streamlined under one roof with effective
                  patient profiles, appointments, treatment plans, and
                  progress-tracking management.
                </p>
                <a
                  href="/"
                  className="streamlined-patient-learn-more"
                  style={{ textDecoration: "underline" }}
                >
                  Learn More
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="Streamlined-Patient-card">
                <div className="streamlined-patient-image-container">
                  <img
                    src={streamlinedPatient3}
                    className="streamlined-patient-image"
                    alt=""
                  />
                </div>
              </div>
              <div className="streamlined-patient-content">
                <p className="streamlined-patient-heading">Integrations </p>
                <p className="streamlined-patient-text">
                  Get tailored exercise plans customized to your individual
                  needs with wide-ranging tools and personalized care.
                </p>
                <a
                  href="/"
                  className="streamlined-patient-learn-more"
                  style={{ textDecoration: "underline" }}
                >
                  Learn More
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
              <div className="Streamlined-Patient-card">
                <div className="streamlined-patient-image-container">
                  <img
                    src={streamlinedPatient4}
                    className="streamlined-patient-image"
                    alt=""
                  />
                </div>
              </div>
              <div className="streamlined-patient-content">
                <p className="streamlined-patient-heading">
                  Home Exercise Library{" "}
                </p>
                <p className="streamlined-patient-text">
                  Suggest <strong> physiotherapy exercises </strong>with over
                  2000+ multi-language descriptions in the patient's native
                  language for easy recovery.
                </p>
                <a
                  href="/"
                  className="streamlined-patient-learn-more"
                  style={{ textDecoration: "underline" }}
                >
                  Learn More
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
              <div className="Streamlined-Patient-card">
                <div className="streamlined-patient-image-container">
                  <img
                    src={streamlinedPatient5}
                    className="streamlined-patient-image"
                    alt=""
                  />
                </div>
              </div>
              <div className="streamlined-patient-content">
                <p className="streamlined-patient-heading">
                  Security & Reliability{" "}
                </p>
                <p className="streamlined-patient-text">
                  Assurance of patient confidentiality & regulatory compliance
                  via robust security measures with our data secrecy.
                </p>
                <a
                  href="/"
                  className="streamlined-patient-learn-more"
                  style={{ textDecoration: "underline" }}
                >
                  Learn More
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
              <div className="Streamlined-Patient-card">
                <div className="streamlined-patient-image-container">
                  <img
                    src={streamlinedPatient6}
                    className="streamlined-patient-image"
                    alt=""
                  />
                </div>
              </div>
              <div className="streamlined-patient-content">
                <p className="streamlined-patient-heading">HEP Features</p>
                <p className="streamlined-patient-text">
                  Our Best Online Home Exercise Program (HEP) can make{" "}
                  <strong> online physical therapy </strong>quick and seamless.
                </p>
                <a
                  href="/"
                  className="streamlined-patient-learn-more"
                  style={{ textDecoration: "underline" }}
                >
                  Learn More
                </a>
              </div>
            </div>
          </Row>
        </div>
      </section>

      {/* testimonial-section */}
      <Testimonial />

      {/* Blog-section */}
      <section className="blog-section">
        <div className="container">
          <div className="center-title-part">
            <h2>
              Ensuring Faster Recovery with Safe & Reliable Exercise Plans
            </h2>
            <p>
              We are here every step of your recovery process to prevent further
              injury and ensure safer recovery within a genuine timeframe.
            </p>
            <button
              className="blog-section-button"
              onClick={() => {
                navigate("/blog");
              }}
            >
              Learn more
            </button>
          </div>

          <Row className="mt-4">
          {isLoading ? (
                        <div className="text-center">Loading...</div>
                      ) : (
                        <>
                          {communitiesList?.items &&
                            communitiesList?.items?.length === 0 && (
                              <div className="text-center">No Blog Found</div>
                            )}
                          {communitiesList?.items &&
                            communitiesList?.items?.slice(0, 3).map((item, index) => {
                              // Function to get next three communities
                              const getRandomCommunities = (currentIndex) => {
                                let filteredItems =
                                  communitiesList.items.filter(
                                    (_, idx) => idx !== currentIndex
                                  );
                                let randomCommunities = [];
                                for (
                                  let i = 0;
                                  i < 3 && filteredItems.length > 0;
                                  i++
                                ) {
                                  let randomIndex = Math.floor(
                                    Math.random() * filteredItems.length
                                  );
                                  randomCommunities.push(
                                    filteredItems[randomIndex]
                                  );
                                  filteredItems.splice(randomIndex, 1); // Remove the selected item
                                }
                                return randomCommunities;
                              };
                              return (
                                <>
                                  <Col
                                    xl="4"
                                    md="6"
                                    lg="12"
                                    key={index}
                                    className="d-flex aling-items-stretch mt-3"
                                  >
                                    <div
                                      className="community-card"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/blogDetails", {
                                          state: {
                                            comunity: item,
                                            nextCommunities:
                                              getRandomCommunities(index),
                                            allCommunities:
                                              communitiesList.items,
                                          },
                                        });
                                      }}
                                    >
                                       <div className="blog-card h-100">
                                       <div className="blog-card-content d-flex align-items-center bg-transparent" >
                    <img src={index === 0  ? blogimage  :index === 1 ? blogimage2 :index === 2 ? blogimage3 : blogimage4  } className="img-fluid"  />
                    </div>
                  <div className="blog-card-details">
                    <p className="blog-heading">
                    {item?.title}
                    </p>
                    <p className="blog-text-style">
                    <p
                                                style={{
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  width: "100%",
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    item?.description
                                                      ?.split(/\s+/)
                                                      .slice(0,30)
                                                      .join(" ") +
                                                    (item?.description?.split(
                                                      /\s+/
                                                    ).length > 30
                                                      ? "..."
                                                      : ""),
                                                }}
                                              />
                                              
                    </p>
                  </div>{" "}
                </div>
                                    </div>
                                  </Col>
                                </>
                              );
                            })}{" "}
                        </>
                      )}
          </Row>
        </div>
      </section>

      {/* footer section */}
      <footer className="footer-section">
        <section className="register-section">
          <Container>
            <div>
              <div className="footer-coppy-part pb-5">
                <div className="email-box d-flex">
                  <h2 className="email-content-part">
                    Ready to experience our features? It's just a matter of one
                    click
                  </h2>
                  <div className="email-part">
                    <input
                      type="email"
                      className="email-input text-white"
                      placeholder="Write email address"
                      value={email}
                      style={{
                        backgroundColor: "rgb(49, 46, 46)",
                        border: "0px",
                      }}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <button
                      type="button"
                      className="sub-button btn btn-primary"
                      onClick={handleSubscribe}
                      disabled={Loading} // Disable button during loading
                    >
                      {Loading ? (
                        <Spinner
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Subscribe"
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end  px-5 mx-5">
                {subscriptionMessage && (
                  <p className="subscription">{subscriptionMessage}</p>
                )}
              </div>
            </div>
          </Container>
          <hr className="horizontal-line" />
        </section>
        <Container>
          <footer className="footer">
            <div className="container">
              <div className="row">
                <div className="footer-col">
                  <a href="/">
                    <img alt="" src={footerLogo} style={{ height: "35px" }} />
                  </a>
                  <p className="mt-4">Together, Towards Better Health</p>
                  <div className="social-links">
                    <a
                      href="https://www.instagram.com/virtuelife_tech/"
                      target="_blank"
                    >
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="https://x.com/virtuelife_tech" target="_blank">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a
                      href="https://www.facebook.com/profile.php?id=61557263103084"
                      target="_blank"
                    >
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/virtuelife_tech/"
                      target="_blank"
                    >
                      <i className="bi bi-linkedin"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@virtuelife_tech"
                      target="_blank"
                    >
                      <i className="bi bi-youtube"></i>
                    </a>
                  </div>
                </div>

                <div className="footer-col-platform">
                  <h4>Platform</h4>
                  <ul className="p-0">
                    <li>
                      <Link to="/patient">Patient</Link>
                    </li>
                    <li>
                      <Link to="/physiotherapist">Physiotherapist</Link>
                    </li>
                    <li>
                      <Link to="/clinic">Clinic</Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-col-support">
                  <h4>Solutions</h4>
                  <ul className="p-0">
                    <li>
                      <Link to="/healthcaresystems">Healthcare Systems</Link>
                    </li>
                    <li>
                      <Link to="/homeexerciselibrary">
                        Home Exercise Library
                      </Link>
                    </li>
                    <li>
                      <Link to="/patientengagement">Patient Engagement</Link>
                    </li>
                    <li>
                      <Link to="/hepfeatures">HEP Features</Link>
                    </li>
                    <li>
                      <Link to="/virtuelifebadge">
                        Virtue Life Certified Badge
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-col-solution">
                  <h4>About</h4>
                  <ul className="p-0">
                    <li>
                      <Link to="/aboutvirtuelife">About Virtue Life</Link>
                    </li>
                    <li>
                      <Link to="/contactus">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/securityreliability">
                        Security & Reliability
                      </Link>
                    </li>
                    <li>
                      <Link to="/developerinformation">
                        Developer Information
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />

            <div>
              <p style={{ fontSize: "14px" }}>
                Copyright 2024 @virtueLife <br />
                Virtue Life is a fitness and wellness tool and does not
                guarantee outcomes in a rehabilitation process. For more
                information, contact your healthcare provider.
              </p>
            </div>
          </footer>
        </Container>
      </footer>
    </>
  );
};

export default Main;
