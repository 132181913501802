import React from "react";
import Layout from "../../components/layout/Layout";
import { Row, Col } from "react-bootstrap";
import homeExerciseLibrary from "../../assets/images/homeExerciseLibrary.png";
import exerciseProtocol2 from "../../assets/images/exerciseProtocol2.png";
import exerciseProtocol1 from "../../assets/images/exerciseProtocol1.png";
import "../../assets/css/HomeExerciseLibrary.css";
import Testimonial from "../../components/testimonial/Testimonial";
import { Helmet } from "react-helmet";

const HomeExerciseLibrary = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Home Exercise Programs for Physiotherapy, Online Workout Programs, Online Workout Coach, Fat Loss Exercise"
        />
        <meta
          name="description"
          content="Find the best home exercise programs for physiotherapy and fat loss. Achieve your goals with easy-to-follow workouts you can do anywhere!"
        />
        <title>
          Best Home Exercise Programs for Physiotherapy | Fat Loss Exercise
        </title>
      </Helmet>
      <Layout>
        {/* Home Exercise Library section */}
        <section className="home-exercise-library-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="header-below-content-part">
                  <h1>Best Home Exercise Programs for Physiotherapy </h1>
                  <p>
                    At Virtue Life, we have developed the world's largest and
                    most accessible{" "}
                    <strong> home exercise program for physiotherapy</strong> &
                    fitness journey. We offer extensive rehabilitation programs
                    &<strong> fat loss exercises</strong> to monitor your
                    patient’s progress.
                  </p>
                  <div className="start-free-trial-orange-button">
                    <a
                      href="https://physiotherapist.virtuelife.ai/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="px-4">
                        <span>Book a FREE Trial</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="image-part">
                  <img
                    src={homeExerciseLibrary}
                    alt=""
                    height={598}
                    width={552}
                  />
                </div>
              </div>
            </div>
          </div>{" "}
        </section>
        {/* Virtue Life gives you time back to focus on your patients section */}
        <section
          className="exercise-protocol-section"
          style={{ background: "rgba(255, 252, 251, 1)" }}
        >
          <div className="container">
            <div className="exercise-protocol-title-part">
              <h2>Virtue Life's Online Workout Program</h2>
              <p>
                Expert therapists and fitness professionals design our{" "}
                <strong> online workout program </strong>for a seamless recovery
                process. Our library offers{" "}
                <strong> rehabilitation exercises</strong> tailored to the
                patient’s physio needs such as mobility, strength & flexibility.
              </p>
            </div>
            <div className="exercise-part">
              <Row className="align-items-center">
                <Col md={7} sm={12}>
                  <div>
                    <div className="exercise-title-part light-theme pb-2">
                      <h2>Rehabilitation Exercises</h2>
                    </div>
                    <div className="exercise-list-part">
                      <ul className="exercise-ul-part list-unstyled">
                        <li>
                          <span className="me-3">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div className="exercise-content-part">
                            Over 2000 exercises for post-surgical rehabilitation
                            to high-intensity fat loss programs with an
                            easy-to-follow video format.
                          </div>
                        </li>
                        <li>
                          <span className="me-3">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div className="exercise-content-part">
                            Be it focused on a specific body part or the entire
                            body, our experienced{" "}
                            <strong> online workout coach</strong> curates an
                            exclusive plan.
                          </div>
                        </li>
                        <li>
                          <span className="me-3">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div className="exercise-content-part">
                            These are the exercise video guides demonstrated by
                            professionals in proper form & tested techniques to
                            meet your desired goals.
                          </div>
                        </li>
                      </ul>
                      <a href="/">
                        <button className="exercise-button">Learn More</button>
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={5} sm={12}>
                  <div className="image-part">
                    <img
                      src={exerciseProtocol1}
                      alt="feature-banner"
                      width="518"
                      height="428"
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <div className="protocol-part">
              <Row className="align-items-center">
                <Col md={7} sm={12}>
                  <div>
                    <div className="desktop-mobile-title-part light-theme pb-2">
                      <h2>Physiotherapy Recovery Program</h2>
                    </div>
                    <div className="protocol-list-part">
                      <ul className="exercise-ul-part list-unstyled">
                        <li>
                          <span className="me-3">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div className="protocol-content-part">
                            These rehabilitation exercises target specific
                            bodies of areas with step-by-step detailed exercises
                            that run from recovery post-surgery, injury
                            rehabilitation, or chronic conditions.
                          </div>
                        </li>
                        <li>
                          <span className="me-3">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div className="protocol-content-part">
                            Therapy practitioners can select from our library of
                            exercise protocols or design their own for the
                            patient to receive customized care.
                          </div>
                        </li>
                        <li>
                          <span className="me-3">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div className="protocol-content-part">
                            Programs are designed to follow the patient's
                            progression and ensure when the patient recovers,
                            the exercises change to reflect their added
                            abilities.
                          </div>
                        </li>
                      </ul>
                      <a href="/">
                        <button className="protocol-button">Learn More</button>
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={5} sm={12}>
                  <div className="image-part">
                    <img
                      src={exerciseProtocol2}
                      alt="feature-banner"
                      width="518"
                      height="428"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        {/* virtue-life-expert-videos-section */}
        <section className="virtue-life-expert-videos-section">
          <div className="container">
            <div className="center-title-part">
              <h2>Weight Loss Exercise Programs For Every Fitness Level</h2>
              <p>
                Experience efficient patient management & seamless collaboration
                with the Virtue Life platform, designed to empower healthcare &
                physiotherapy professionals to optimize care delivery without a
                hassle.
              </p>
            </div>
            <div className="text-center arcade-button">
              <div className="start-free-trial-orange-button">
                <button className="px-4">
                  <span>Book Your Slot</span>
                </button>
              </div>
            </div>
            <br />
            <div
              className="bg-transparent border-0 shadow-lg"
              style={{
                position: "relative",
                "padding-bottom": "calc(56.16264294790343% + 41px)",
                height: "0",
                width: "100%",
              }}
            >
              <iframe
                src="https://demo.arcade.software/Earsz5M02Cwc2lc5nfw4?embed&show_copy_link=true"
                title="Virtue Life - Physiotherapist"
                frameBorder="0"
                loading="lazy"
                webkitAllowFullScreen
                mozAllowFullScreen
                allowFullScreen
                allow="clipboard-write"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  colorScheme: "light",
                }}
              ></iframe>
            </div>
          </div>
        </section>
        {/* testimonial-section */}
        <Testimonial />
        {/* try Virtue Life today section */}
        <section className="try-virtue-life-today-section">
          <div className="container">
            <div className="try-virtue-life-today-card">
              <div className="try-virtue-life-today-content">
                <p className="try-virtue-life-today-heading">Try Virtue Life</p>
                <p className="try-virtue-life-today-text">Get Started Now!</p>
                <div className="store-name mt-4">
                  <a
                    href="https://physiotherapist.virtuelife.ai/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="px-4">
                      <span>Try 7 Days FREE Trial</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default HomeExerciseLibrary;
