import React from "react";
import Layout from "../../components/layout/Layout";
import { Row, Col } from "react-bootstrap";
import physioLaptopImage from "../../assets/images/home-laptop.png";
import "../../assets/css/Physiotherapist.css";
import appStore from "../../assets/images/app-store.png";
import playStore from "../../assets/images/play-store.png";
import desktopMobile from "../../assets/images/desktop-mobile.png";
import Testimonial from "../../components/testimonial/Testimonial";
import Certified from "../../components/certified/Certified";
import { Helmet } from "react-helmet";

const Physiotherapist = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Physical Therapy, physical therapist, Doctor of Physical Therapy, Pelvic Floor PT, Online Physiotheapy Consultation"
        />
        <meta
          name="description"
          content="Experience online physiotherapy consultations in the USA. Connect with expert physical therapists using advanced software for personalized care!"
        />
        <title>
          Online Physiotheapy Consultation in USA | Physical Therapist Software
        </title>
      </Helmet>
      <Layout>
        {/* physiotherapist section */}
        <section className="physiotherapist-section position-relative d-flex align-items-center">
          <div className="container">
            <div className="row my-5 py-4">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="header-below-content-part">
                  <h1>Transform Your Physiotherapy Practice in Healthcare</h1>
                  <p>
                    From a physical therapy service center looking to streamline
                    processes to scheduling online physiotherapy consultations,
                    Virtue Life strives for better patient outcomes with its
                    high-performance software destined as your go-to physical
                    therapist companion.
                  </p>

                  <div className="start-free-trial-orange-button">
                    <a
                      href="https://physiotherapist.virtuelife.ai/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="px-4">
                        <span>Book a FREE Trial</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="d-flex banner-image">
                  <img
                    src={physioLaptopImage}
                    alt=""
                    height={"100%"}
                    width={"100%"}
                  />
                </div>
              </div>
            </div>
          </div>{" "}
        </section>

        {/* Unified Communication & Profile Management for Doctors section */}
        <section
          className="ucpm-doctors-section"
          style={{ background: "rgba(255, 252, 251, 1)" }}
        >
          <div className="container">
            <div className="center-title-part">
              <h2>Your Trusted Partner In The Doctor of Physical Therapy</h2>
              <p>
                By concentrating on optimizing{" "}
                <strong> pelvic floor PT </strong>
                therapy & rehabilitation services, our software is a
                prerequisite for any medical practice to enhance patient
                outcomes.
              </p>
            </div>

            <div className="mt-4">
              <Row>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Integrated Patient Management: </h5>
                      <p>
                        Organize and have all patient records from one platform.
                        Our system supports everything ranging from appointment
                        scheduling to medical records and billing.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Therapy Service Software</h5>
                      <p>
                        As a <strong> doctor of physical therapy,</strong> the
                        patient's progress is easily traced. From rehabilitation
                        to <strong> online physiotherapy consultations,</strong>{" "}
                        our platform can assign customized workout plans to
                        patients & can be accessed via iOS & Android mobile
                        apps.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Data Security</h5>
                      <p>
                        This is the importance of patient data. Our health
                        software ensures top-tier security protocols so that all
                        delicate information is stored and transferred using
                        maximum encryption.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Simplify Work Flow</h5>
                      <p>
                        All appointments, patient data, and{" "}
                        <strong> physical therapy</strong>
                        schedules on one integrated platform - Improved decision
                        time to produce timely, accurate care from patients to
                        your systems.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100 ">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Improve Patient Care</h5>
                      <p>
                        Personalized treatments with comprehensive data about
                        the patient at your fingertips. Track patient progress
                        over time with our intuitive{" "}
                        <strong> physical therapist </strong>interface.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="next-card">
                  <div className="ucpm-doctor-card h-100">
                    <div>
                      <span className="me-2">
                        <svg
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="success"
                        >
                          <path
                            d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                            fill="#12D372"
                          />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <h5>Reduce Overhead in Administration</h5>
                      <p>
                        Automated processes, put administrative overhead to rest
                        & set your healthcare staff to work more on patient care
                        than paperwork.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>

        {/* What your patients can do with Virtue Life desktop and mobile apps */}
        <section className="desktop-mobile-section">
          <div className="container">
            <Row className="align-items-center">
              <Col md={7} sm={12}>
                <div>
                  <div className="desktop-mobile-title-part light-theme">
                    <h2>How Virtue Life Can Assist Your Patients?</h2>
                    <p>
                      Elevate your <strong> physical therapy practice</strong>{" "}
                      experience with the latest Virtue Life software, designed
                      to bolster patient outcomes & streamline their timely
                      healthcare needs. We offer personalized exercise plans for
                      your patient’s desired language to accelerate recovery &
                      improve patient satisfaction.
                    </p>
                  </div>
                  <div className="desktop-mobile-list-part">
                    <ul className="desktop-mobile-patient list-unstyled">
                      <div>
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>Easy access to video-based exercises.</div>
                        </li>
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>
                            Track daily progress & get real-time updates.
                          </div>
                        </li>
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>
                            Indulge in the right exercise at the right time.
                          </div>
                        </li>
                      </div>
                      <div className="start-margin">
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>
                            Receive insights from{" "}
                            <strong> Doctor of Physical Therapy</strong>{" "}
                            experts.
                          </div>
                        </li>
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>
                            Book online{" "}
                            <strong> physiotherapy consultations </strong>at
                            your convenience.
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>

                  <div className="app-play-button">
                    <button
                      className="appStore"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "https://apps.apple.com/in/app/virtue-life-physiotherapy/id6476655610",
                          "_blank"
                        );
                      }}
                    >
                      <img src={appStore} alt="appStore" className="" />
                    </button>
                    <button
                      className="playStore"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.recureme.patient&hl=en_IN&gl=US&pli=1",
                          "_blank"
                        );
                      }}
                    >
                      <img src={playStore} alt="playStore" />
                    </button>
                  </div>
                </div>
              </Col>
              <Col md={4} sm={12}>
                <div className="image-part ms-4">
                  <img
                    src={desktopMobile}
                    alt="feature-banner"
                    width="425"
                    height="516"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </section>

        {/* Virtue Life is useful for section */}
        <section
          className="virtue-life-useful-section"
          style={{ background: "rgba(255, 252, 251, 1)" }}
        >
          <div className="container">
            <div className="center-title-part">
              <h2>Virtue Life Perks for Clinics & Practices</h2>
              <p>
                Be it a single clinic or multiple branches, Virtue Life assists
                <strong> Physical Therapists</strong> in offering exceptional
                healthcare facilities to ease their process.
              </p>
            </div>

            <div className="mt-4">
              <Row>
                <Col lg={4} md={4} sm={12}>
                  <div className="virtue-life-useful-card">
                    <h5>Private Practice</h5>
                    <p>
                      Offer individualized <strong> pelvic floor PT </strong>
                      programs with quick & seamless trackable features on
                      patient progress.
                    </p>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                  <div className="virtue-life-useful-card next-card">
                    <h5>Single Clinic</h5>
                    <p>
                      Profoundly allocate & efficiently monitor personalized
                      workout practices to assist patients in a speedy recovery.
                    </p>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                  <div className="virtue-life-useful-card next-card">
                    <h5>Multiple Clinic</h5>
                    <p>
                      Collaborate with multiple demographics by enabling doctors
                      to offer consistent & enhanced treatment models.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>

        {/* step section */}
        <section className="step-section">
          <div className="container">
            <div className="center-title-part">
              <h2>Get started with these 3 simple steps!</h2>
            </div>

            <Row>
              <Col
                lg={4}
                md={4}
                sm={12}
                className="step-section-data-card-grid"
              >
                <div className="d-flex align-items-center">
                  <div className="w-100 d-none d-md-block"></div>
                  <div className="mx-auto">
                    <div className="sequential-number-list">1</div>
                  </div>
                  <div className="dashed-border-bottom w-100 d-none d-md-block"></div>
                </div>
                <div className="dashed-border-left pt-3 w-0 m-auto"></div>
                <div className="custom-card-step-section mx-3">
                  <h5>Include Patient Details</h5>
                  <p>
                    Fill in the desired patient information via our customized
                    <strong> clinic management system.</strong>
                  </p>
                </div>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={12}
                className="step-section-data-card-grid"
              >
                <div className="dashed-border-left pt-3 w-0 m-auto d-md-none d-block"></div>
                <div className="d-flex align-items-center">
                  <div className="dashed-border-bottom w-100 d-none d-md-block"></div>
                  <div className="mx-auto">
                    <div className="sequential-number-list">2</div>
                  </div>
                  <div className="dashed-border-bottom w-100 d-none d-md-block"></div>
                </div>
                <div className="dashed-border-left pt-3 w-0 m-auto"></div>
                <div className="custom-card-step-section mx-3">
                  <h5>Allocate Workout Procedures</h5>
                  <p>
                    Create a custom exercise plan tailored to meet the
                    healthcare demands of each patient.{" "}
                  </p>
                </div>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={12}
                className="step-section-data-card-grid"
              >
                <div className="dashed-border-left pt-3 w-0 m-auto d-md-none d-block"></div>
                <div className="d-flex align-items-center">
                  <div
                    className="dashed-border-bottom d-none d-md-block"
                    style={{ width: "40%" }}
                  ></div>
                  <div className="number-margin">
                    <div className="sequential-number-list">3</div>
                  </div>
                  <div className="dashed-border-bottom w-100 d-none d-lg-none"></div>
                </div>
                <div className="dashed-border-left pt-3 w-0 m-auto"></div>
                <div className="custom-card-step-section mx-3 ">
                  <h5>Track Daily Progress</h5>
                  <p>
                    Modify seamless exercise routines based on patient feedback
                    & track their overall progress.
                  </p>
                </div>
              </Col>
            </Row>
            <div
              className="start-free-trial-orange-button mt-4"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <a
                href="https://physiotherapist.virtuelife.ai/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="px-4">
                  <span>Book a FREE Trial</span>
                </button>
              </a>
            </div>
          </div>
        </section>

        <Testimonial />
        <Certified />
      </Layout>
    </>
  );
};

export default Physiotherapist;
