import Layout from "../layout/Layout"
import { Col, Container, Row } from "react-bootstrap"

function Privacy (){
    return (
        <>
          <Layout>
          <Container>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div
                    className="banner-content"
                    style={{ marginTop: "3rem", marginBottom: "3rem" }}
                  >
                    <h1 className="my-5" style={{ fontWeight: "700" }}>
                      Privacy Policy
                    </h1>
                    <h4 style={{ fontWeight: "700" }}>
                      Welcome to VirtueLife's Privacy Policy.
                    </h4>

                    <div className="text-muted" style={{ lineHeight: "2" }}>
                      <p>
                        VirtueLife respects your privacy and is committed to
                        protecting your personal data. This Privacy Policy
                        explains how we collect, process, and secure your
                        personal data when you use our Services.
                      </p>

                      <p className="mt-3">
                        1. Important Information and Who We Are
                      </p>
                      <span>
                        <span className="fw-bold">
                          Purpose of this Privacy Policy:
                        </span>{" "}
                        This document aims to give you information on how
                        VirtueLife collects and processes your personal data
                        through your use of our Services.
                      </span>
                      <br />
                      <span>
                        <span className="fw-bold">
                          Controller Information:{" "}
                        </span>
                        VirtueLife is the controller and responsible for your
                        personal data.
                      </span>
                      <br />
                      <span>
                        <span className="fw-bold">Contact Details:</span> For
                        any privacy-related concerns, please contact our Data
                        Protection Officer (DPO) at [DPO's contact details].
                      </span>
                      <br />

                      <p className="mt-3">2. The Data We Collect About You</p>
                      <span>
                        <span className="fw-bold">
                          Categories of Personal Data:
                        </span>{" "}
                        Including but not limited to identity data (name, date
                        of birth), contact data (address, email, phone number),
                        financial data, and transaction data.
                      </span>
                      <br />
                      <span>
                        <span className="fw-bold">
                          Special Categories of Personal Data:{" "}
                        </span>
                        We do not collect sensitive personal information as
                        defined under Indian law, such as health, biometric, or
                        genetic data, unless explicitly provided by you for
                        specific services.
                      </span>
                      <br />

                      <p className="mt-3">3. How We Use Your Personal Data</p>
                      <span>
                        <span className="fw-bold">
                          Purposes for Processing:
                        </span>{" "}
                        We use your data to provide and improve our Services,
                        manage your account, process transactions, and for
                        customer support.
                      </span>
                      <br />
                      <span>
                        <span className="fw-bold">
                          Lawful Basis for Processing:{" "}
                        </span>
                        Includes fulfilling a contract with you, complying with
                        legal obligations, and our legitimate interests.
                      </span>
                      <br />
                      <span>
                        <span className="fw-bold">
                          Marketing and Promotional Offers:{" "}
                        </span>
                        We may use your data to send promotional offers if you
                        opt-in to receive such communications.
                      </span>
                      <br />

                      <p className="mt-3">
                        4. Disclosures of Your Personal Data
                      </p>
                      <span>
                        <span className="fw-bold">
                          Sharing with Third Parties:
                        </span>{" "}
                        Your data may be shared with service providers, business
                        partners, and as required by law.
                      </span>
                      <br />
                      <span>
                        <span className="fw-bold">
                          International Transfers:{" "}
                        </span>
                        We may transfer data outside of India if necessary for
                        providing the Services, under adequate data protection
                        measures.
                      </span>
                      <br />

                      <p className="mt-3">5. Data Security</p>
                      <span>
                        <span className="fw-bold">Security Measures:</span>We
                        implement appropriate technical and organizational
                        measures to protect your data.
                      </span>
                      <br />
                      <span>
                        <span className="fw-bold">
                          Data Breach Procedures:{" "}
                        </span>{" "}
                        In case of a data breach, we will take prompt action and
                        notify you and the relevant authorities as required by
                        law.
                      </span>
                      <br />

                      <p className="mt-3">6. Data Retention</p>
                      <span>
                        <span className="fw-bold">Retention Periods:</span>We
                        retain your data as long as necessary for the purposes
                        it was collected for and as required by law.
                      </span>
                      <br />
                      <span>
                        <span className="fw-bold">Data Erasure: </span> Upon
                        request, we will delete your data in accordance with
                        legal requirements.
                      </span>
                      <br />

                      <p className="mt-3">7. Your Legal Rights</p>
                      <span>
                        <span className="fw-bold">Your Rights:</span>You have
                        the right to request access, correction, erasure, and
                        portability of your data, and to object to its
                        processing.
                      </span>
                      <br />
                      <span>
                        <span className="fw-bold">
                          How to Exercise Your Rights:{" "}
                        </span>
                        Contact our DPO to exercise your rights under this
                        policy.
                      </span>
                      <br />

                      <p className="mt-3">8. Cookies Policy</p>
                      <span>
                        <span className="fw-bold">Use of Cookies:</span>We use
                        cookies to improve our Services and user experience.
                      </span>
                      <br />
                      <span>
                        <span className="fw-bold">Managing Cookies: </span> You
                        can manage your cookie preferences through your browser
                        settings.
                      </span>
                      <br />

                      <p className="mt-3">9. Changes to the Privacy Policy</p>
                      <span>
                        <span className="fw-bold">Policy Updates:</span>We may
                        update this policy from time to time, and we encourage
                        you to review it regularly.
                      </span>
                      <br />
                      <span>
                        <span className="fw-bold">
                          Your Duty to Inform Us of Changes:{" "}
                        </span>{" "}
                        Please inform us of any changes to your personal data.
                      </span>
                      <br />

                      <p className="mt-3">10. Third-Party Links</p>
                      <span>
                        <span className="fw-bold">
                          External Websites and Services:
                        </span>
                        Our Services may include links to third-party websites,
                        which are not covered by this Privacy Policy.
                      </span>
                      <br />

                      <p className="mt-3">11. Glossary</p>
                      <span>
                        <span className="fw-bold">
                          Definitions and Interpretations:
                        </span>
                        Terms used in this policy are defined for clarity and
                        legal consistency.
                      </span>
                      <br />

                      <p>VirtueLife may update this policy periodically.</p>
                    </div>


                    <h1 className="my-5" style={{ fontWeight: "700" }}>
                      VirtueLife Payment Policy
                    </h1>

                    <div className="text-muted" style={{ lineHeight: "2" }}>

                      <p className="mt-3">
                        1. Subscription Plans
                      </p>
                      <span>
                        VirtueLife offers both monthly and annual subscription plans. Prices and specific services are detailed on our subscription page.
                      </span>
                    
                      <br />

                      <p className="mt-3">2. Payment Methods</p>
                      <span>
                      We accept payments via credit card, debit card, and other digital payment platforms as specified on the payment page.
                      </span>

                      <p className="mt-3">3. Billing Cycle</p>
                      <span>
                        Monthly subscriptions are billed on the same date each month from the date of subscription. Annual subscriptions are billed annually on the date of the initial subscription.
                      </span>
                      <br />

                      <p className="mt-3">
                        4. Renewals
                      </p>
                      <span>
                        Subscriptions automatically renew unless cancelled at least 24 hours before the renewal date.
                      </span>
                      <br />

                      <p className="mt-3">5. Cancellations</p>
                      <span>
                        Subscriptions can be cancelled anytime via your account settings. Access to services continues until the end of the current paid period.
                      </span>
                      <br />
                      

                      <p className="mt-3">6. Refunds</p>
                      <span>
                      We do not offer refunds for partially used periods unless required by law.
                      </span>
                      <br />

                      <p className="mt-3">7. Changes in Prices</p>
                      <span>
                      Prices are subject to change with prior notice. Current subscribers will receive notice of changes in pricing terms before they take effect.
                      </span>
                      <br />

                      <p className="mt-3">8. Secure Transactions</p>
                      <span>
                      All transactions are securely processed. We are committed to protecting your personal and payment information.
                      </span>
                      <br />
                      <p>VirtueLife may update this policy periodically.</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            </Layout>
        </>
    )
}
export default Privacy