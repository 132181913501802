import React from "react";
import Layout from "../../components/layout/Layout";
import { Row, Col } from "react-bootstrap";
import desktopMobilePatientEngagement from "../../assets/images/desktopMobilePatientEngagement.png";
import appStore from "../../assets/images/app-store.png";
import playStore from "../../assets/images/play-store.png";
import PatientEngagement from "../../assets/images/patientEngagement.png";
import "../../assets/css/PatientEngagement.css";
import { Helmet } from "react-helmet";

const patientEngagement = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Patient Engagement Solutions, Digital Patient Engagement Platform, Patient Care"
        />
        <meta
          name="description"
          content="Experience the best patient engagement in physical therapy in the USA with Virtue Life. Enhance your recovery journey with tailored support and resources!"
        />
        <title>
          Best Patient Engagement in Physical Therapy in USA | | Virtue Life
        </title>
      </Helmet>
      <Layout>
        {/* patient engagement section */}
        <section className="patient-engagement-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="header-below-content-part">
                  <h1>
                    Best Patient Engagement in Physical Therapy in the USA{" "}
                  </h1>
                  <p>
                    Virtue Life believes patients must act proactively toward
                    regaining a better life. Our{" "}
                    <strong>digital patient engagement platform</strong> assists
                    enhanced <strong>patient engagement solutions </strong> via
                    customized support, personal exercise programs & valuable
                    resources guiding patients in healing.
                  </p>

                  <div className="start-free-trial-orange-button">
                    <a
                      href="https://physiotherapist.virtuelife.ai/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="px-4">
                        <span>Book a FREE Trial</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="image-part">
                  <img
                    src={PatientEngagement}
                    alt="Banner showcasing home exercise prescription"
                    height={460}
                    width={547}
                  />
                </div>
              </div>
            </div>
          </div>{" "}
        </section>

        {/* Virtue Life: redefining patient care beyond the clinic section */}
        <section
          className="virtue-life-patient-care-section"
          style={{ background: "rgba(255, 252, 251, 1)" }}
        >
          <div className="container">
            <div className="center-title-part">
              <h2>How Does Virtue Life Enhance Patient Engagement?</h2>
              <p>
                Virtue Life is more than just a physical therapy application; it
                is a<strong> digital patient engagement platform</strong> that
                aids therapists as much as their patients in conveying with
                ease, monitoring progress, and taking care of them in the best
                possible way.
              </p>
              <div className="start-free-trial-orange-button">
                <a
                  href="https://physiotherapist.virtuelife.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="px-4">
                    <span>Book a FREE Trial</span>
                  </button>
                </a>
              </div>
            </div>

            <div className="mt-4">
              <Row>
                <Col lg={6} md={6} sm={12} className="next-card">
                  <div className="virtue-life-patient-care-card h-100">
                    <div>
                      <h5>Personalized Exercise Programs</h5>
                      <p>
                        With Virtue Life, health professionals can develop and
                        prescribe personalized exercise programs per patient's
                        requirements.{" "}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12} className="next-card">
                  <div className="virtue-life-patient-care-card">
                    <div>
                      <h5>Monitoring the Patient’s Progress</h5>
                      <p>
                        The Virtue Life tools enable patients to log on, input
                        their pain levels, and follow symptom records to track
                        their progress.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col lg={6} md={6} sm={12} className="next-card">
                  <div className="virtue-life-patient-care-card h-100">
                    <div>
                      <h5>Patient Notifications & Reminders</h5>
                      <p>
                        <strong> Patient engagement solutions </strong>keep
                        patients active and on course by automatically
                        triggering notifications.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12} className="next-card">
                  <div className="virtue-life-patient-care-card">
                    <div>
                      <h5>Better Patient Compliance</h5>
                      <p>
                        Rehabilitation treatment and exercises devoted to{" "}
                        <strong> patient engagement solutions</strong> are more
                        extensive as the application guides them via automated
                        exercises on Virtue Life.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        {/* What your patients can do with Virtue Life desktop and mobile apps section */}
        <section className="desktop-mobile-section">
          <div className="container">
            <Row className="align-items-center">
              <Col md={7} sm={12}>
                <div>
                  <div className="desktop-mobile-title-part light-theme">
                    <h2>
                      Benefits of Virtue Life for Healthcare Professionals
                    </h2>
                    <p>
                      Virtue Life designs solutions for patient engagement
                      through easy-to-use tools that motivate at every stage.
                      Choose Virtue Life for your patients, and you offer them
                      the best in physiotherapy by keeping them informed,
                      motivated, and engaged in their{" "}
                      <strong> patient care </strong>& recovery.
                    </p>
                  </div>
                  <div className="desktop-mobile-list-part">
                    <ul className="desktop-mobile-patient list-unstyled">
                      <div>
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>Advanced Patient Engagement</div>
                        </li>
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>Streamlined Exercise Prescription</div>
                        </li>
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>Enhanced Patient Motivation</div>
                        </li>
                      </div>
                      <div className="start-margin">
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>Real-Time Progress Monitoring</div>
                        </li>
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>Leveraged Patient Compliance</div>
                        </li>
                        <li>
                          <span className="me-2">
                            <svg
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="success-small"
                            >
                              <path
                                d="M17 0C7.65 0 0 7.65 0 17C0 26.35 7.65 34 17 34C26.35 34 34 26.35 34 17C34 7.65 26.35 0 17 0ZM13.6 25.5L5.1 17L7.497 14.603L13.6 20.689L26.503 7.786L28.9 10.2L13.6 25.5Z"
                                fill="#12D372"
                              />
                            </svg>
                          </span>
                          <div>Quick Treatment Modifications</div>
                        </li>
                      </div>
                    </ul>
                  </div>
                  <div
                    className="app-play-button"
                    style={{ textAlign: "start" }}
                  >
                    <button
                      className="appStore"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "https://apps.apple.com/in/app/virtue-life-patient-wellness/id6476655418",
                          "_blank"
                        );
                      }}
                    >
                      <img src={appStore} alt="appStore" className="me-2" />
                    </button>
                    <button
                      className="playStore"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.recureme.patient&hl=en_IN&gl=US&pli=1",
                          "_blank"
                        );
                      }}
                    >
                      <img src={playStore} alt="playStore" />
                    </button>
                  </div>
                </div>
              </Col>
              <Col md={4} sm={12}>
                <div className="image-part">
                  <img
                    src={desktopMobilePatientEngagement}
                    alt="feature-banner"
                    width="446"
                    height="387"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </section>

        {/* try virtue  life today section */}
        <section className="try-virtue-life-today-section">
          <div className="container">
            <div className="try-virtue-life-today-card">
              <div className="try-virtue-life-today-content">
                <p className="try-virtue-life-today-heading">
                  Try Virtue Life Today
                </p>
                <p className="try-virtue-life-today-text">
                  Ready to get started?
                </p>
                <div className="store-name mt-4">
                  <a
                    href="https://physiotherapist.virtuelife.ai/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="px-4">
                      <span>Book a FREE Trial</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default patientEngagement;
