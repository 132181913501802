import React, { useState } from "react";
import "../../assets/css/Pricing.css";
import { Container, Button, Nav, Navbar } from "react-bootstrap";
import headerLogoBlack from "../../assets/images/header-logo-black.svg";
import { Popover, OverlayTrigger } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import include from "../../assets/images/include.svg";
import logoDeveloperInformation from "../../assets/images/logo-developer-information.svg";
import logoContactUs from "../../assets/images/logo-contact-us.svg";
import logoBlog from "../../assets/images/logo-blog.svg";
import logoPhysio from "../../assets/images/logo-physio.svg";
import logoPatient from "../../assets/images/logo-patient.svg";
import logoPatientEngagement from "../../assets/images/logo-patient-engagement.svg";
import logoHomeExerciseLibrary from "../../assets/images/logo-home-exercise-library.svg";
import logoHEPFeatures from "../../assets/images/logo-hep-features.svg";
import logoVirtueLifeBadge from "../../assets/images/logo-virtue-life-badge.svg";
import logoHealthcareSystem from "../../assets/images/logo-healthcare-systems.svg";
import logoSecurityReliability from "../../assets/images/logo-security-reliability.svg";
import { Helmet } from "react-helmet";
import { useNavigate, useLocation, Link } from "react-router-dom";

const Pricing = () => {
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedPlan, setSelectedPlan] = useState("Monthly");
  const [showPopover, setShowPopover] = useState({
    platform: false,
    solutions: false,
    about: false,
  });
  const handlePlanChange = (plan) => {
    setSelectedPlan(plan);
  };
  const handleMouseEnter = (type) => {
    setShowPopover((prev) => ({ ...prev, [type]: true }));
  };

  const handleMouseLeave = (type) => {
    setShowPopover((prev) => ({ ...prev, [type]: false }));
  };
  const popover1 = (
    <Popover
      id="popover1"
      onMouseEnter={() => handleMouseEnter("solutions")}
      onMouseLeave={() => handleMouseLeave("solutions")}
    >
      <div className="solutions-popup-card" style={{ height: "fit-content" }}>
        <div className="solutions-popup-card-up">
          <Link to="/virtuelifebadge" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoVirtueLifeBadge}
                    alt="Private Practice logo"
                    width={21.6}
                    height={17.87}
                  />
                </div>
                <p className="popup-logo-text ms-2">Virtue Life Badge</p>
              </div>
              <p className="popup-text-style">
                Earn recognition based on your progress with a personalized
                Virtue Life badge.
              </p>
            </div>
          </Link>
          <div className="popup-vertical-spacer">
            <div className="popup-vertical-divider" />
          </div>
          <Link to="/healthcaresystems" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoHealthcareSystem}
                    alt="Healthcare Systems logo"
                    width={15.37}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Healthcare Systems</p>
              </div>
              <p className="popup-text-style">
                Easy integration with healthcare systems for streamlined patient
                management.
              </p>
            </div>
          </Link>
          <div className="popup-vertical-spacer">
            <div className="popup-vertical-divider" />
          </div>
          <Link to="/hepfeatures" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoHEPFeatures}
                    alt="HEP Features logo"
                    width={8.92}
                    height={21.5}
                  />
                </div>
                <p className="popup-logo-text ms-2">HEP Features</p>
              </div>
              <p className="popup-text-style">
                Unlock modern Home Exercise Program (HEP) tools with real-time
                tracking.
              </p>
            </div>
          </Link>
        </div>
        <div className="solutions-popup-card-down mt-3">
          <Link to="/homeexerciselibrary" className="popup-card-link">
            <div className="popup-card ">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoHomeExerciseLibrary}
                    alt="Home Exercise Library logo"
                    width={12.16}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Home Exercise Library</p>
              </div>
              <p className="popup-text-style">
                Get access to 2000+ home exercise videos for seamless patient
                recovery.
              </p>
            </div>
          </Link>
          <div className="popup-vertical-spacer">
            <div className="popup-vertical-divider" />
          </div>
          <Link to="/patientengagement" className="popup-card-link">
            <div className="popup-card" style={{ marginRight: "-40px" }}>
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoPatientEngagement}
                    alt="Patient Engagement logo"
                    width={15.87}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Patient Engagement</p>
              </div>
              <p className="popup-text-style">
                Enhance patient interaction with customized healthcare with
                timely updates.
              </p>
            </div>
          </Link>
        </div>
      </div>
    </Popover>
  );

  const popover2 = (
    <Popover
      id="popover2"
      onMouseEnter={() => handleMouseEnter("platform")}
      onMouseLeave={() => handleMouseLeave("platform")}
    >
      <div className="platform-popup-card" style={{ height: "fit-content" }}>
        <Link to="/physiotherapist" className="popup-card-link">
          <div className="popup-card">
            <div className="d-flex">
              <img
                src={logoPhysio}
                alt="Physiotherapist logo"
                width={30}
                height={30}
              />
              <p className="popup-logo-text ms-2 mt-1">Physiotherapist</p>
            </div>
            <p className="popup-text-style">
              Offer custom therapy plans via video guides & personalized
              exercises.
            </p>
            <p className="link-text">Learn More</p>
          </div>{" "}
        </Link>
        <div className="popup-vertical-spacer">
          <div className="popup-vertical-divider" />
        </div>
        <Link to="/patient" className="popup-card-link">
          <div className="popup-card">
            <div className="d-flex">
              <img
                src={logoPatient}
                alt="Patient logo"
                width={30}
                height={30}
              />
              <p className="popup-logo-text ms-2 mt-1">Patient</p>
            </div>
            <p className="popup-text-style">
              Grab personalized patient care with easy & trackable home workout
              plans.
            </p>
            <p className="link-text">Learn More</p>
          </div>
        </Link>
        <div className="popup-vertical-spacer">
          <div className="popup-vertical-divider" />
        </div>
        <Link to="/clinic" className="popup-card-link">
          <div className="popup-card">
            <div className="d-flex">
              <img src={logoPhysio} alt="Clinic logo" width={30} height={30} />
              <p className="popup-logo-text ms-2 mt-1">Clinic</p>
            </div>
            <p className="popup-text-style">
              Simplify clinical functioning by offering enhanced care using
              digital tools.
            </p>
            <p className="link-text">Learn More</p>
          </div>
        </Link>
      </div>
    </Popover>
  );

  const popover3 = (
    <Popover
      id="popover3"
      onMouseEnter={() => handleMouseEnter("about")}
      onMouseLeave={() => handleMouseLeave("about")}
    >
      <div className="about-popup-card" style={{ height: "fit-content" }}>
        <div className="about-popup-card-up">
          <Link to="/aboutvirtuelife" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoPhysio}
                    alt="Virtue Life logo"
                    width={18}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">About Virtue Life</p>
              </div>
              <p className="popup-text-style">
                A patient-centric platform leveraging patient care via tailored
                exercise programs.
              </p>
            </div>{" "}
          </Link>
          <div className="popup-vertical-spacer">
            <div className="popup-vertical-divider" />
          </div>
          <Link to="/securityreliability" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoSecurityReliability}
                    alt="Security & Trust logo"
                    width={15.59}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Security & Reliability</p>
              </div>
              <p className="popup-text-style">
                HIPAA-compliance-based encrypted systems ensure reliable patient
                data handling.
              </p>
            </div>{" "}
          </Link>
          <div className="popup-vertical-spacer">
            <div className="popup-vertical-divider" />
          </div>
          <Link to="/developerinformation" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoDeveloperInformation}
                    alt="Developer Information logo"
                    width={15.76}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Developer Information</p>
              </div>
              <p className="popup-text-style">
              Access API documentation, integration guides, and tools to seamlessly connect your platform with VirtueLife.
              </p>
            </div>
          </Link>
        </div>
        <div className="about-popup-card-down mt-3">
          <Link to="/contactus" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img
                    src={logoContactUs}
                    alt="Contact Us logo"
                    width={15.21}
                    height={18}
                  />
                </div>
                <p className="popup-logo-text ms-2">Contact Us</p>
              </div>
            </div>
          </Link>
          <Link to="/blog" className="popup-card-link">
            <div className="popup-card">
              <div className="d-flex">
                <div className="popup-logo">
                  <img src={logoBlog} alt="Blog logo" width={17} height={17} />
                </div>
                <p className="popup-logo-text ms-2">Blog</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </Popover>
  );
  const isNavbarSection = location.pathname === "/pricing";
  // Apply conditional styles
  const navbarStyle = {
    backgroundColor: isNavbarSection ? "#ffede8" : "initial",
  };
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Pricing Plans, Affordable Therapy, Physical Therapist Software Pricing, Online Therapy Costs, Virtue Life"
        />
        <meta
          name="description"
          content="Discover our flexible pricing plans for online physical therapy and home exercise programs. Get quality care at a price that fits your budget with Virtue Life."
        />
        <title>
          Pricing Plans | Virtue Life - Affordable Physical Therapy Solutions
        </title>
      </Helmet>

      <header
        className={`${scrolled ? "scrolled" : ""} header-part`}
        style={{ backgroundColor: "#ffede8" }}
      >
        <Navbar expand="md">
          <Container>
            <Navbar.Brand
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}
            >
              <img src={headerLogoBlack} alt="Logo" width="191" height="50" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className={`${scrolled ? "scrolled" : ""} navbar-part`}
                style={navbarStyle}
            >
              <Nav>
                <OverlayTrigger
                  trigger="hover"
                  placement="bottom"
                  show={showPopover.solutions}
                  overlay={popover1}
                >
                  <div className="flex-row-container">
                    <div>
                      <div
                        href=""
                        className="navbar-nav-link"
                        onMouseEnter={() => handleMouseEnter("solutions")}
                        onMouseLeave={() => handleMouseLeave("solutions")}
                      >
                        Solutions
                      </div>
                    </div>
                    <div className="svg-container-home">
                      <svg
                        fill="none"
                        viewBox="0 0 10 10"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0"
                        y="0"
                      >
                        <path
                          id="Vector 14"
                          d="M1,3l4,4l4,-4"
                          stroke="black"
                          xmlns="http://www.w3.org/2000/svg"
                        />
                      </svg>
                    </div>
                  </div>
                </OverlayTrigger>
                <div className="ms-2">
                  <Link
                    to="/virtuelifebadge"
                    className="mobile-view mobile-display"
                  >
                    Virtue Life Badge
                  </Link>
                  <Link
                    to="/healthcaresystems"
                    className="mobile-view mobile-display"
                  >
                    Healthcare Systems
                  </Link>
                  <Link
                    to="/hepfeatures"
                    className="mobile-view mobile-display"
                  >
                    HEP Features
                  </Link>
                  <Link
                    to="/homeexerciselibrary"
                    className="mobile-view mobile-display"
                  >
                    Home Exercise Library
                  </Link>
                  <Link
                    to="/patientengagement"
                    className="mobile-view mobile-display"
                  >
                    Patient Engagement
                  </Link>
                </div>
                <OverlayTrigger
                  trigger="hover"
                  placement="bottom"
                  show={showPopover.platform}
                  overlay={popover2}
                >
                  <div className="flex-row-container">
                    <div>
                      <div
                        href=""
                        className="navbar-nav-link"
                        onMouseEnter={() => handleMouseEnter("platform")}
                        onMouseLeave={() => handleMouseLeave("platform")}
                      >
                        Platform
                      </div>
                    </div>
                    <div className="svg-container-home">
                      <svg
                        fill="none"
                        viewBox="0 0 10 10"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0"
                        y="0"
                      >
                        <path
                          id="Vector 14"
                          d="M1,3l4,4l4,-4"
                          stroke="black"
                          xmlns="http://www.w3.org/2000/svg"
                        />
                      </svg>
                    </div>
                  </div>
                </OverlayTrigger>
                <div className="ms-2">
                  <Link
                    to="/physiotherapist"
                    className="mobile-view mobile-display"
                  >
                    Physiotherapist{" "}
                  </Link>
                  <Link to="/patient" className="mobile-view mobile-display">
                    Patient{" "}
                  </Link>
                  <Link to="/clinic" className="mobile-view mobile-display">
                    Clinic{" "}
                  </Link>
                </div>
                <OverlayTrigger
                  trigger="hover"
                  placement="bottom"
                  show={showPopover.about}
                  overlay={popover3}
                >
                  <div className="flex-row-container">
                    <div>
                      <div
                        href=""
                        className="navbar-nav-link"
                        onMouseEnter={() => handleMouseEnter("about")}
                        onMouseLeave={() => handleMouseLeave("about")}
                      >
                        About
                      </div>
                    </div>
                    <div className="svg-container-home">
                      <svg
                        fill="none"
                        viewBox="0 0 10 10"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0"
                        y="0"
                      >
                        <path
                          id="Vector 14"
                          d="M1,3l4,4l4,-4"
                          stroke="black"
                          xmlns="http://www.w3.org/2000/svg"
                        />
                      </svg>
                    </div>
                  </div>
                </OverlayTrigger>
                <div className="ms-2">
                  <Link
                    to="/aboutvirtuelife"
                    className="mobile-view mobile-display"
                  >
                    About Virtue Life{" "}
                  </Link>
                  <Link
                    to="/securityreliability"
                    className="mobile-view mobile-display"
                  >
                    Security & Reliability{" "}
                  </Link>
                  <Link
                    to="/developerinformation"
                    className="mobile-view mobile-display"
                  >
                    Developer Information{" "}
                  </Link>
                  <Link to="/contactus" className="mobile-view mobile-display">
                    Contact us{" "}
                  </Link>
                  <Link to="/blog" className="mobile-view mobile-display">
                    Blog{" "}
                  </Link>
                </div>
                <Nav.Link as={Link} to="/pricing" className="mt-1">
                  Pricing
                </Nav.Link>
                <Nav.Link as={Link} to="/blog" className="mt-1">
                  Blog
                </Nav.Link>
                <hr />
                <Nav.Link
                  href="#feature-section"
                  className="mobile-display"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open("https://physiotherapist.virtuelife.com/login");
                  }}
                >
                  Log in
                </Nav.Link>
                <Nav.Link
                  href="#benifit-section"
                  className="mobile-display"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open("https://physiotherapist.virtuelife.ai/login");
                  }}
                >
                  Try for Free{" "}
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <div className="d-flex deskto-display">
              <button
                className="me-2 header-outline-btn"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    "https://physiotherapist.virtuelife.ai/login",
                    "_blank"
                  );
                }}
              >
                Log in
              </button>{" "}
              <button
                className="me-2 header-outline-btn-free"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    "https://physiotherapist.virtuelife.ai/login",
                    "_blank"
                  );
                }}
              >
                <span>Try for Free </span>
              </button>{" "}
            </div>
          </Container>
        </Navbar>
      </header>
      <div className="payment-physio-section" style={{ paddingTop: "4rem" }}>
        <div className="container">
          <div className="title-part-best">
            <h6 className="">pricing</h6>
            <h2 className="split-heading">
              <span>One Simple Pricing Plan</span>
            </h2>
            <div className="subscription-plan-selector">
              <button
                className={
                  selectedPlan === "Monthly"
                    ? "monthly-button active"
                    : "quarterly-button"
                }
                onClick={() => handlePlanChange("Monthly")}
              >
                Monthly
              </button>
              <button
                className={
                  selectedPlan === "Quarterly"
                    ? "monthly-button active"
                    : "quarterly-button"
                }
                onClick={() => handlePlanChange("Quarterly")}
              >
                Quarterly
              </button>
              <button
                className={
                  selectedPlan === "Yearly"
                    ? "monthly-button active"
                    : "yearly-button"
                }
                onClick={() => handlePlanChange("Yearly")}
              >
                Yearly
              </button>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <div className="pricing-container">
                <div className="price-container">
                  {selectedPlan === "Monthly" && (
                    <>
                      <p className="price-strike-through">₹699</p>
                      <p className="price-highlighted-text">₹399</p>
                      <p className="monthly-price-label">per month</p>
                    </>
                  )}
                  {selectedPlan === "Quarterly" && (
                    <>
                      <p className="price-strike-through">₹1599</p>
                      <p className="price-highlighted-text">₹349</p>
                      <p className="monthly-price-label mb-3"> per month</p>

                      <p className="monthly-price-label text-secondary">
                        {" "}
                        ₹999 per quarter
                      </p>
                    </>
                  )}
                  {selectedPlan === "Yearly" && (
                    <>
                      <p className="price-strike-through">₹4999</p>
                      <p className="price-highlighted-text">₹299</p>
                      <p className="monthly-price-label  mb-3">per month</p>
                      <p className="monthly-price-label text-secondary">
                        {" "}
                        ₹3499 per year
                      </p>
                    </>
                  )}
                </div>
                <div className="promo-banner">
                  <p className="promo-banner-text-style">
                    Special Offer, Grab It Now!
                  </p>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <section
        className="include-section"
        style={{ backgroundColor: "#FFEDE8" }}
      >
        <div className="container">
          <p className="include-title-text">What we include</p>
          <div className="include-allcontent-section d-flex justify-content-center ">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="include-content-section ">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Monthly <b>30 Patient Access</b>
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Browse a library of over <b>1,600+ exercise</b>
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Print and email workout plans (Up to 50 times.){" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Expand your patient base{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Track patient activity & get max result{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Web & Mobile App{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Access more then <b>250 protocols</b>{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Add your own exercise and workout protocols{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Seamless workout management{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Access large Physiotherapist’s community{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="include-list-container">
            <div className="store-name d-flex justify-content-center">
              <a
                href="https://physiotherapist.virtuelife.ai/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="px-3 rounded"
                  style={{
                    backgroundColor: "rgb(255, 72, 23)",
                    color: "white",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    border: "none",
                    width: "250px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      "https://physiotherapist.virtuelife.ai",
                      "_blank"
                    );
                  }}
                >
                  Subscribe
                </button>
              </a>
            </div>{" "}
            <p className="sales-team-contact-info mb-0">
              <span className="friendly-sales-team-text-style">
                or connect our friendly sales team at{" "}
              </span>
              <span className="protocol-count-text-style">
                support@virtuelife.ai
              </span>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
