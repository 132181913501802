import React from "react";
import "../../assets/css/Testimonial.css";
import { Row, Col } from "react-bootstrap";
import profile from "../../assets/images/profile.png";

const Testimonial = () => {
  return (
    <>
      {/* testimonial-section */}
      <section className="testimonial-section">
        <div className="container">
          <div className="center-title-part">
            <h2>
              Join Us for Your Painless Recovery with Customized Home Exercise
              Plans{" "}
            </h2>
            <p>
              Get everything aligned in sync with Virtue Life related to your
              recovery process. Save valuable time and bring your life back on
              time with better productivity.
            </p>
          </div>

          <Row>
            <Col lg={6} md={6}>
              <div className="testimonial-card ">
                <p>
                Virtue life has transformed how I manage my patients' rehabilitation. The vast exercise video library allows me to customize workouts specifically tailored to each patient's needs. The platform's user-friendly interface and robust tracking features ensure my patients stay on the right path to recovery. It's a must-have tool for any physiotherapist.
                </p>
                <div className="testimonial-author">
                  <img
                    src={profile}
                    alt="Profile of a healthcare professional"
                    className="testimonial-image"
                  />
                  <div className="author-info">
                    <p className="author-name">Olivia</p>
                    <p className="author-title">Physiotherapist</p>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={6} md={6}>
              <div className="testimonial-card">
                <p>
                Using Virtue Life has streamlined my practice in ways I never thought possible. From adding new patients to assigning personalized workout plans, everything is incredibly efficient. The ability to print or email exercise plans and track patient progress has made a significant difference in my workflow and patient satisfaction. Virtue Life is an invaluable resource for healthcare professionals.
                </p>
                <div className="testimonial-author">
                  <img
                    src={profile}
                    alt="Profile of a healthcare professional"
                    className="testimonial-image"
                  />
                  <div className="author-info">
                    <p className="author-name">Emma</p>
                    <p className="author-title">Physiotherapist</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className="testimonial-pagination">
            <button className="pagination-button">
              <span className="pagination-arrow">&larr;</span>
            </button>
            <button className="pagination-button">
              <span className="pagination-arrow">&rarr;</span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
